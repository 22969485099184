.ChatHeader_container {
  width: 100%;
  max-height: 66px;
  min-width: 550px;

  position: relative;

  background-color: var(--white);

  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1280px) {
    min-width: 500px;
  }
  .ChatHeader_info {
    max-width: 70%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    & > *:first-child {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
      font-size: 1rem;
      font-weight: 500;
      color: var(--gray-950);
    }
    & > *:last-child {
      height: 18px;
      width: 1rem;
    }
  }
}

.ChatHeader_buttons {
  justify-content: flex-end;
  button {
    padding: 8px;
  }
  &__mobile {
    display: flex;
    flex-direction: column;
    padding: 0.5em 0.5em;

    &__info {
      color: var(--gray-800) !important;

      svg {
        color: #6a7178 !important;
      }
    }
    span {
      padding-right: 0.625em;
    }
    button {
      border: none !important;
      text-align: left;
      padding-left: 0.75em;
    }
  }
}

.ChatHeader_container__mobile {
  display: flex;
  height: 66px !important;
  width: 100%;
  background: white;
  justify-content: space-between;
  align-items: center;

  &__menu {
    position: absolute;
    z-index: 1;
    background: #ffffff;

    box-shadow:
      0px 0px 2px rgba(0, 0, 0, 0.1),
      0px 6px 12px rgba(12, 43, 66, 0.16),
      0px 0px 1px rgba(17, 60, 92, 0.06);
    border-radius: 4px;
    width: 95vw;
    right: calc(5vw / 2);
    top: 4em;
  }

  &__button {
    width: 38px;
    height: 38px;

    margin: 1em;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 1.75em;
      width: 1.75em;
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0d1822;

    display: inline-block;
    width: 12rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: center;
  }

  &__channel {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #6a7178;
    text-align: center;
  }
}

.Mobile_backdrop {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.FilterDropdown {
  button {
    background: none;
    border: none;
    color: black !important;
    svg {
      margin: 0;
    }
  }

  button[aria-expanded='true'] {
    background-color: var(--gray-300);
  }

  .FilterDropdownMenu {
    padding: 12px 12px 4px 12px;
    border: none;
    box-shadow:
      0px 0px 2px rgba(0, 0, 0, 0.1),
      0px 6px 12px rgba(12, 43, 66, 0.16),
      0px 0px 1px rgba(17, 60, 92, 0.06);
  }

  .FilterRadio {
    padding: 4px 0;
    label {
      font-size: 12px;
    }
  }
}
