.ChatInfo_container {
  background-color: var(--white);
  padding: 1rem;
  height: 100%;
  overflow: auto;
  border-left: 1px solid var(--gray-200);

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;

    opacity: 0;
    animation: chagInfo_appearance 100ms ease-in-out forwards;

    & > h5 {
      font-size: 1rem;
      color: var(--gray-600);
      font-size: 0.75rem;
    }
    & > .ChatInfo_info {
      color: var(--gray-800);
      font-size: 0.75rem;
    }
  }

  .nav {
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 0;
  }
  .ChatInfo_divider {
    height: 1px;
    width: 100%;
    background-color: var(--gray-200);
  }

  &__mobile {
    display: flex;
    flex-direction: row;
  }
}
@keyframes chagInfo_appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
