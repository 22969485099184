@import 'settingsMixins';
.Settings {
  .AnalyticActionPanel_actionPanel {
    bottom: 0;
    top: initial;
    border-top: none;
    height: 48px;
    min-height: 48px;
  }

  .Operator_description {
    display: block;
    max-width: 540px;
  }

  .Operators_createButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    margin-top: 24px;
    & > *:first-child {
      width: 320px;
      max-width: calc(100vw - 3rem);
    }
    @media (max-width: 620px) {
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: stretch;
      gap: 1rem;
      width: 100%;
      & > *,
      input {
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }

  .Limits_wrapper {
    margin: 0 0 16px 0;
    max-width: auto !important;
    width: 100%;
    height: 94px;
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;

    a {
      text-decoration: none;
    }

    .Title {
      font-weight: bold;
    }

    p {
      margin-bottom: 8px;
    }

    @media (min-width: 620px) {
      margin-left: 24px;
      max-width: 290px;
    }
  }
  .Operators_operator {
    @include element();
    justify-content: flex-start;
    & > *:not(:last-child) {
      width: 40%;
      @media (max-width: 620px) {
        width: 100%;
      }
    }

    [data-test-id='OperatorInfo.container'] {
      min-width: 350px;
    }

    .operator_name {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_group {
      padding: 2px 4px;
      border-radius: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media (max-width: 620px) {
      flex-direction: column !important;
      gap: 1rem;
      & > *:first-child {
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
  }
}
.OperatorInfo_container {
  background-color: var(--white);
  transition: 300ms ease-in-out;
  padding: 4px 8px 4px 0;
  width: 100%;
  &:hover {
    background-color: var(--indigo-100);
    border-radius: 4px;
  }
}

.Operators_operatorList_wrapper {
  @media (max-width: 620px) {
    width: 100% !important;
  }
}
