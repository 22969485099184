@import "~@just-ai/just-ui/scss/basic/custom/functions";
@import "~@just-ai/just-ui/scss/basic/custom/variables";

.justui_popover {
  color: $black;
  &.dark {
    color: white;
    .popover {
      background-color: $greyblue-dark;
      border-color: $black;
      &[x-placement^="bottom"] {
        .arrow {
          &:after {
            border-bottom-color: $greyblue-dark !important;
          }
          &::before {
            border-bottom-color: $black !important;
          }
        }
      }
      &[x-placement^="top"] {
        .arrow {
          &:after {
            border-top-color: $greyblue-dark !important;
          }
          &::before {
            border-top-color: $black !important;
          }
        }
      }
      &[x-placement^="left"] {
        .arrow {
          &:after {
            border-left-color: $greyblue-dark !important;
          }
          &::before {
            border-left-color: $black !important;
          }
        }
      }
      &[x-placement^="right"] {
        .arrow {
          &:after {
            border-right-color: $greyblue-dark !important;
          }
          &::before {
            border-right-color: $black !important;
          }
        }
      }
    }
    .justui_popover-header {
      border-bottom-color: $black;
    }

    button.justui_popover-btn-close {
      color: $black-light;
    }
  }
  .popover {
    max-width: 330px;
    position: relative;
    box-shadow: $popover-box-shadow;
    border: 1px solid $popover-border-color;
  }

  &--without-shadow {
    .popover {
      box-shadow: none;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $popover-border-color;
  }

  &-title {
    margin: $popover-padding-small $popover-padding-small $popover-padding-small
      $popover-padding-large;
    font-weight: 700;
    font-size: $popover-font-size;
    word-break: break-word;
    letter-spacing: 0.02em;
  }

  &-body {
    padding: $popover-padding-large $popover-padding-large
      $popover-padding-small $popover-padding-large;
    font-size: $popover-font-size;
    &--small {
      font-size: $popover-font-size-small;
    }
    &--without-buttons {
      padding-bottom: $popover-padding-large;
    }
    &--close-button {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-right: calc(#{$popover-padding-small} + 36px);
    }
  }

  button.justui_popover-btn-close {
    font-size: 1rem;
    padding: $popover-padding-small $popover-padding-large;
    color: $grey-600;
    opacity: 1;
    &--absolute {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &-buttons {
    padding: 0 $popover-padding-large $popover-padding-large
      $popover-padding-large;
    display: flex;
    align-items: center;
    button:first-child {
      margin-right: $popover-padding-small;
    }
  }
}
