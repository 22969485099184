.OperatorChatTabs_container {
  background-color: var(--white);
  padding: 1rem;
  border-right: 1px solid var(--gray-200);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content min-content auto;
  position: relative;
}

#iframe-wrapper {
  position: absolute;
  bottom: 16px;
  left: 16px;
}
