.high-height-modal {
  .modal-body {
    height: 80vh;
    max-height: 700px;
    padding-left: 0 !important;
  }

  .scrollbar-container {
    padding-right: 0 !important;
  }
  .modal-body-container {
    height: 100%;

    & > .scrollbar-container {
      padding-left: 24px;
    }
  }
  .IconButtons-wrap-prepend {
    z-index: 999 !important;
  }
  input {
    background-color: var(--gray-100);
  }
  .ModalFieldset {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    p {
      margin: 0;
    }
  }
}

.MainTab-SelectList {
  > [class='relativeWrapper'] {
    > [class*='scrollbar-container'] {
      padding: 0 .75rem;
      > [class*='just-select-item'] {
        border-radius: 4px;
      }
      > [class*='focused'] {
        background: var(--indigo-100);
      }
      > [class*='active'] {
        background-color: transparent;

        &:hover {
          background: var(--indigo-100);
        }
      }
    }
  }
}
