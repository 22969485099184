@import "../custom/functions";
@import "../custom/variables";

$primary: $jaicp-600;
$btn-box-shadow: $jaicp-500;

$primary-100: $jaicp-100;
$primary-200: $jaicp-200;
$primary-300: $jaicp-300;
$primary-400: $jaicp-400;
$primary-500: $jaicp-500;
$primary-600: $jaicp-600;
$primary-700: $jaicp-700;
$primary-800: $jaicp-800;
$primary-900: $jaicp-900;
$primary-950: $jaicp-950;

$theme-colors-states--buttons: map-merge(
  $theme-colors-states--buttons,
  (
    "primary": (
      "default": $primary,
      "hover": $primary-700,
      "active": $primary-800,
      "disabled": $grey-300,
      "focus": $primary-500,
      "flat-active-bg": $primary-200,
    ),
    "secondary": (
      "default": $secondary,
      "hover": rgba(0, 0, 0, 0.04),
      "active": $grey-200,
      "disabled": $grey-300,
      "focus": $primary-500,
      "flat-active-bg": $grey-200,
    ),
  )
);

// $theme-colors-states--buttons: (
//   "primary": (
//     "hover": $jaicp-700,
//     "active": $jaicp-800,
//     "disabled": $grey-300,
//     "focus": $jaicp-700,
//   ),
//   "secondary": (
//     "hover": $jaicp-700,
//     "active": $jaicp-800,
//     "disabled": $grey-300,
//     "focus": $jaicp-700,
//   ),
//   "success": (
//     "hover": $jaicp-700,
//     "active": $jaicp-800,
//     "disabled": $grey-300,
//     "focus": $jaicp-700,
//   ),
//   "info": (
//     "hover": $jaicp-700,
//     "active": $jaicp-800,
//     "disabled": $grey-300,
//     "focus": $jaicp-700,
//   ),
//   "warning": (
//     "hover": $jaicp-700,
//     "active": $jaicp-800,
//     "disabled": $grey-300,
//     "focus": $jaicp-700,
//   ),
//   "danger": (
//     "hover": $jaicp-700,
//     "active": $jaicp-800,
//     "disabled": $grey-300,
//     "focus": $jaicp-700,
//   ),
//   "light": (
//     "hover": $jaicp-700,
//     "active": $jaicp-800,
//     "disabled": $grey-300,
//     "focus": $jaicp-700,
//   ),
//   "dark": (
//     "hover": $jaicp-700,
//     "active": $jaicp-800,
//     "disabled": $grey-300,
//     "focus": $jaicp-700,
//   ),
// );

:root {
  --main-spinner-background: rgba(255,255,255, 0.9);
  --compact-spinner-background: rgba(255,255,255, 1);
}
