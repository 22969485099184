.Params_container {
  position: relative;
  overflow: auto;
  height: 100%;
  padding-bottom: 1rem;
  width: calc(100vw - 5rem);
  @media (max-width: 620px) {
    display: grid;
  }

  fieldset {
    max-width: 540px;
  }
}

.SwitchButton {
  padding-left: 3.2rem !important;
}

.playSourceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0 !important;

  & > *:first-child {
    margin-right: 8px;
    width: 100%;
    z-index: 1;
  }

  .just-select-backdrop {
    z-index: auto;
  }
}

.SoundNotifications__wrapper {
  button {
    height: 36px;
    width: 36px;
  }
  > div {
    margin-bottom: 8px;
    max-width: 282px;
  }
}
