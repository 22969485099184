.rdrMonthYearPicker {
  margin-top: 0.5rem;

  height: 100%;

  grid-row: 2/3;
  grid-column: 1 / -1;

  background-color: #fff;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 1rem;
  z-index: 2;

  height: 100%;
  & > button {
    border: none;
    background-color: transparent;
    height: 2rem;
    min-width: 31%;
    max-width: 144px;
    margin: 0 !important;
    color: #4B535B;
    transition: 200ms ease-in-out;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:active, &:focus {
      outline: none;
    }
    &.rdrActiveElem {
      border: 1px solid var(--primary);
      border-radius: 2px;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
  &.rdrMonthYearPickerSingle {
    & > button {
      min-width: 28%;

    }
  }
}
.rdrMonthsSwitcher {
  padding: 0 6px;
  position: absolute;
  grid-column: 1/-1;
  grid-row: 1/2;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    font-size: 1rem;
    text-transform: capitalize;
    &:disabled {
      pointer-events: none;
    }
  }
  .rdrMonthButton {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    path {
      fill: var(--rdr-grey-dark-white);
      transition: 300ms;
    }

    &:hover {
      path {
        fill: var(--rdr-blue);
      }
    }
  }
}

