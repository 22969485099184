.PromtersTabWrapper {
  height: 100%;
  gap: 0;
  grid-gap: 0;

  input {
    background-color: var(--gray-100);
  }
  .PromptersAnswers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-basis: 0;
    width: 100%;

    &.WithPrompts {
      align-items: start;
      flex-direction: column;
      overflow: hidden;
      display: flex;
      justify-content: end;

      .PrompterMessagesWrapper {
        overflow-y: auto;
        width: 100%;
      }
    }

    &__description {
      text-align: center;
      color: var(--gray-500);
    }
  }

  .Prompter {
    cursor: pointer;
    width: 100%;
    padding: 8px;
    border-radius: 4px;

    flex-direction: column;
    &:hover {
      background-color: var(--indigo-100);
    }
  }

  .PromptersPagination {
    padding: 16px;
    display: flex;
    justify-content: center;
  }

  .PromptersTabBottom {
    width: 100%;
  }

  &__prompter {
    padding: 8px 12px;
    background-color: var(--gray-100);
    border-radius: 4px;
    width: 100%;
    color: var(--gray-950);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
