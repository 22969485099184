@mixin tabContainer() {
  position: relative;
  display: grid;
  gap: 1.5rem;
  height: 100%;
  grid-template: 'description nothing' 'button nothing' 'list limit';
  grid-template-columns: 800px minmax(100px, 290px);
  grid-template-rows: min-content 2rem auto;
  overflow: hidden;
  @media (max-width: 1240px) {
    grid-template-columns: 600px minmax(100px, 290px);
  }
  @media (max-width: 620px) {
    grid-template: 'description' 'button' 'list';
    grid-template-columns: 100%;
    grid-template-rows: min-content min-content min-content;
    overflow: auto;
  }
}

@mixin elementsList() {
  grid-area: list;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 8px;
  overflow: auto;
  @media (max-width: 620px) {
    overflow: initial;
    padding-bottom: 1rem;
  }
}

@mixin element() {
  background-color: var(--white);
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  & > *:last-child > button {
    border: 1px solid var(--gray-300);
  }
  @media (max-width: 620px) {
    height: auto;
    min-height: auto;
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 1rem;
    gap: 0.25rem;
    & > {
      width: 100%;
    }
    & > *:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      gap: 0rem;
      margin-top: 0.25rem;
      & > * {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.25rem;
      }
    }
  }
}
