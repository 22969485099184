.Messages {
  &__element {
    margin-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: var(--gray-600);
    text-align: center;
    font-size: 14px;
    animation: messageAppearance;
    animation-duration: 300ms;
    align-items: flex-start;
  }
}

.ClientDialog_MessagesContainer {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: var(--background-lightest);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-300);
    border-radius: 5px;
    &:hover {
      background-color: var(--gray-500);
    }
  }
}
