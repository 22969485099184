@use "sass:math";

.table {

  thead th {
    font-weight: 700;
    font-size: $table-head-font-size;
    line-height: #{math.div(16, 16)}rem;

    letter-spacing: $letter-spacing-small;
  }

}
