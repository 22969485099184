.justui_icon.svg-inline--fa {
  width: 1rem;
  height: 1rem;

  &.fa-xs {
    width: 0.5rem;
    height: 0.5rem;
  }

  &.fa-sm {
    width: 0.75rem;
    height: 0.75rem;
  }

  &.fa-lg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.fa-20 {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
  }

  &.fa-xl {
    width: 2rem;
    height: 2rem;
  }

  &.fa-1x {
    font-size: 1em;
  }

  &.fa-xxl {
    width: 2rem;
    height: 2rem;
  }

  @for $multiplier from 1 through 10 {
    &.fa-#{$multiplier}x {
      width: #{$multiplier}rem;
      height: #{$multiplier}rem;
      font-size: #{$multiplier}rem;
    }
  }

  &:not(.btn-color) {
    &.fa-color-primary {
      color: var(--primary);
    }
    &.fa-color-info {
      color: var(--info);
    }
    &.fa-color-secondary {
      color: var(--secondary);
    }
    &.fa-color-success {
      color: var(--success);
    }
    &.fa-color-warning {
      color: var(--warning);
    }
    &.fa-color-danger {
      color: var(--danger);
    }
  }
}

.form-control-label span.justui__icon-wrapper {
  margin-left: 10px;
}

.input-group.input-group-lg .justui_icon.svg-inline--fa.fa-lg,
.ReactSelect.lg .justui_icon.svg-inline--fa.fa-lg,
.btn .justui_icon.svg-inline--fa.fa-lg {
  width: 1.25rem;
  height: 1.25rem;
}
