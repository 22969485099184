.just-ui_spinner_body {
  animation: justUISpinner;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes justUISpinner {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
