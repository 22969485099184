$base-size: 4px;

:root {
  --scrollbar-color: 224, 225, 227;
  --gray-alt--gray-50: #f9fafc;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullSize {
  width: 100%;
  height: 100%;
}

.font-color-normal {
  color: var(--gray-800);
}

.font-color-light-gray {
  color: var(--gray-600);
}

.font-color-placeholder-default {
  color: var(--gray-500);
}

.font-color-bright {
  color: var(--gray-950);
}

.font-color-danger {
  color: var(--danger);
}

.font-color-primary {
  color: var(--primary);
}

.font-bold {
  font-weight: bold;
}

.font-link {
  color: var(--primary);
  border-bottom: 1px dashed var(--primary);
}

.icon-withBorder {
  border: 1px solid var(--gray-300);
}
.iconDisabled {
  cursor: not-allowed !important;
  opacity: 0.8;
}

.gray-select {
  .just-select-container {
    background-color: var(--gray-100);
    input {
      background-color: var(--gray-100);
    }
  }

  .just-select-container:not(.error) {
    background-color: var(--gray-100);
    border: 1px solid var(--gray-200);
  }
}

.just-select-container.sm {
  min-height: 30px;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-12 {
  font-size: 0.75rem;
}

.font-size-16 {
  font-size: 1rem;
}

.font-size-20 {
  font-size: 1.25rem;
}

.font-size-24 {
  font-size: 1.5rem;
}

$parametors: (
  gap: '.gap',
  padding: '.padding',
  padding-top: '.padding-top',
  padding-bottom: '.padding-bottom',
  padding-left: '.padding-left',
  padding-right: '.padding-right',
  margin: '.margin',
  margin-top: '.margin-top',
  margin-bottom: '.margin-bottom',
  margin-left: '.margin-left',
  margin-right: '.margin-right',
  border-radius: '.border-radius',
);

$parametorSizes: (
  0x: 0,
  1x: 4,
  2x: 8,
  3x: 12,
  4x: 16,
  5x: 20,
  6x: 24,
  7x: 30,
  8x: 36,
);

@each $parametor, $parametor-value in $parametors {
  @each $size, $size-value in $parametorSizes {
    #{$parametor-value}-#{$size} {
      #{$parametor}: #{$size-value}px;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.paper-block {
  background-color: var(--white);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px rgba(12, 43, 66, 0.06), 0px 0px 1px rgba(17, 60, 92, 0.06);
  border-radius: 4px;
  padding: 1rem;
}

.form-control:focus {
  box-shadow: none !important;
}

.reset-button {
  background-color: transparent;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
  }
}

.mobile-modal {
  @media (max-width: 620px) {
    top: 50%;
    transform: translateY(-50%) !important;
    &.mobile-no-margin {
      margin: 0 !important;
    }
    &.full-height-modal {
      height: 100% !important;
      .modal-content {
        height: 100% !important;
        & > form {
          height: calc(100% - 72px);
          .modal-body-container {
            max-height: calc(100vh - 3.5rem - 132px);
          }
        }
      }
    }
    .modal-footer {
      padding: 0;
    }
    .modal-base-buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: stretch;
      gap: 0.5rem;
      width: 100%;
      margin: 0 !important;
      & > * {
        width: 100%;
        margin: 0 !important;
      }
    }
  }
}
