.spinner {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(228, 229, 230, 0.5);

  &.spinner-error {
    cursor: default;
  }

  i {
    font-size: 125px;
    line-height: 100%;

    &.success {
      color: var(--success);
    }

    &.error {
      color: var(--danger);
    }
  }

  &.fixed {
    position: fixed;
  }

  &-text {
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    color: var(--gray-950);
    white-space: pre-wrap;
  }
}

.just-ui-spinner {
  cursor: initial !important;
}
