@import "Days.scss";

.rdrCustomContainerForDatePicker {
  --rdr-blue: var(--primary-600);
  --rdr-blue-l-35: var(--gray-100);
  --rdr-blue-l-10: var(--gray-100);
  --rdr-blue-d5: var(--primary-600);
  --rdr-blue-end: var(--primary-600);
  --rdr-dark-blue: var(--primary-950);
  --rdr-black: var(--gray-950);
  --rdr-grey-dark-white: var(--gray);
  --rdr-white: var(--white);

  --rdr-day: var(--gray-700);

  --rdr-disabled: var(--grey-300);
  --rdr-black-l-40: var(--grey);
  --rdr-black-l-93: var(--gray-200);
  --rdr-disabled-background: transparent;

  &.rdrDark {
    --rdr-white: var(--primary-950);
    --rdr-day: var(--gray-400);
    --rdr-disabled: var(--grey-700);
    --rdr-disabled-background: transparent;

  }

  * {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
  }

  .rdrPositionContainer {
    position: relative;
    min-height: 54px;
    width: auto;
    z-index: 10;
  }

  .rdrContainer {
    position: relative;
    max-height: 54px;
    height: auto;
    width: auto;
    display: inline-block;
  }

  .rdrCalendarContainer {
    top: 54px;
    left: 0;
    position: absolute;

    padding: 0;

    box-shadow: 0px 0px 1px rgba(17, 60, 92, 0.06), 0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.1);
    transition: 300ms ease-in-out;
    background-color: var(--rdr-white);
    max-height: 0px;
    overflow: hidden;

    display: grid;
    gap: 8px 0;
    grid-template-rows: 24px auto auto;
    grid-template-columns: 224px 16px 224px;
    grid-template-areas:
    'firstMonth . secondMonth'
    'months  months months'
    'firstTime . secondTime';

    &.rdrDatePickerSingleMode {
      grid-template-rows: 24px auto auto;
      grid-template-columns: 224px;
      grid-template-areas:
    'firstMonth'
    'months'
    'firstTime';
    }

    &.rdrShowCalendar {
      padding: 1rem;
      max-height: 700px;

      .rdrInputDateMainContainer .rdrInputDateContainer .rdrInputDate {

      }
    }
  }

  &.rdrIntegratedCalendar {
    .rdrContainer {
      max-height: initial;
    }
    .rdrCalendarContainer {
      position: relative;
      top: 0;
      left: 0;
      box-shadow: none;
      padding: 0.5rem 0.5rem;
      margin: 0 -0.5rem;
    }

    .rdrInputDateContainer {
      width: 220px;
    }
  }

  .rdrInputDateIconContainer {
    path {
      fill: var(--rdr-black-l-40);
      transition: 300ms;
    }
  }

  .rdrCalendarWrapper {
    box-sizing: border-box;
    background: var(--rdr-white);
    display: inline-flex;
    flex-direction: column;
    user-select: none;
    grid-area: months;
  }

  .rdrMonthAndYearWrapper {
    display: none;
  }

  .rdrMonths {
    padding: 0;
    display: -webkit-box;
    display: flex;
    gap: 1rem;

    .rdrMonth {
      width: 224px;
      padding: 0;

      text-align: center;

      .rdrMonthName {
        height: 41px;
        display: none;

        border-bottom: 1px solid var(--rdr-black-l-93);
        color: var(--rdr-black);
      }

      .rdrWeekDays, .rdrDays {
        padding: 0;
      }

      .rdrWeekDays {
        padding-bottom: 0;
        margin-bottom: 6px;
      }

      .rdrDays {
        padding-top: 0;
      }
    }
  }

  @include days();

  .rdrInfiniteMonths {
    overflow: auto;
  }

  .rdrDateRangeWrapper {
    user-select: none;
  }


  @supports (-ms-ime-align: auto) {
    .rdrDay {
      flex-basis: 14.285% !important;
    }
  }

  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    pointer-events: none;
  }

  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    pointer-events: none;
  }

  .rdrStaticRanges {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .rdrStaticRange {
    font-size: inherit;
  }

  .rdrInputRange {
    display: -webkit-box;
    display: flex;
  }

  .rdrCalendarWrapper {
    color: #000000;
    font-size: 12px;
  }

  .rdrDateDisplayWrapper {
    background-color: rgb(239, 242, 247);
  }

  .rdrDateDisplay {
    margin: 0.833em;
  }

  .rdrMonthPicker, .rdrYearPicker {
    margin: 0 5px
  }

  .rdrMonth {
    padding: 0 0.833em 1.666em 0.833em;
  }

  .rdrMonth .rdrWeekDays {
    padding: 0;
  }


  .rdrDay:focus {
    outline: 0;
  }


  .rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 226px;
    border-right: solid 1px #eff2f7;
    background: var(--rdr-white);
  }

  .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
    color: currentColor;
    font-weight: 600;
  }

  .rdrStaticRange {
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid #eff2f7;
    padding: 0;
    background: var(--rdr-white)
  }

  .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
    background: #eff2f7;
  }

  .rdrStaticRangeLabel {
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
  }

  .rdrInputRanges {
    padding: 10px 0;
  }

  .rdrInputRange {
    -webkit-box-align: center;
    align-items: center;
    padding: 5px 20px;
  }

  .rdrInputRangeInput {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122);

    &:focus, &:hover {
      border-color: rgb(180, 191, 196);
      outline: 0;
      color: #333;
    }
  }

  .rdrMonthCustomName {
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    box-shadow: none !important;
    background-color: transparent;
    border: none;
    padding: 0 0.5rem;
    width: calc(50% - 3rem);
    justify-content: center;

    &.rdrMonthCustomNameSingle {
      width: auto;
      white-space: nowrap;
    }

    &:focus {
      outline: none;
    }

    h3 {
      text-transform: capitalize;
      font-size: 16px;
      color: var(--rdr-black);
      font-weight: normal;
    }

    * {
      fill: var(--rdr-grey-dark-white);
    }

    & > svg {
      margin-left: 0.25rem;
      transition: 300ms ease-in-out;
      transform: rotateZ(0deg);
      height: 1rem;
      width: 1rem;
    }

    & > .rdrChooseYearAndMonth {
      position: absolute;
      top: 45px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .rdrMonthCustomNameOpened {
    background-color: var(--rdr-black-l-93);
    box-shadow: 1px 0 0 0px var(--rdr-black-l-93) !important;

    & > svg {
      transform: rotateZ(180deg);
    }
  }

  .rdrFirstYearMonthSelect {
    grid-row: 2/3;
    grid-column: 1/2;
    z-index: 2;
  }

  .rdrSecondYearMonthSelect {
    grid-row: 2/3;
    grid-column: 3/4;
    z-index: 2;

  }

  .rdrFirstYearMonthSelect,
  .rdrSecondYearMonthSelect {
    position: absolute;
    opacity: 0;
    transition: opacity 300ms ease-in-out, pointer-events 0ms 300ms;
    pointer-events: none;
    height: 100%;
    overflow: hidden;
    border: 1px solid var(--rdr-black-l-93);
    background-color: var(--rdr-white);

    &.rdrYearMonthSelectShow {
      opacity: 1;
      pointer-events: initial;
    }

    display: grid;
    grid-template-columns: 142px 81px;
    grid-auto-flow: column;

    & > div {
      height: 100%;
      width: calc(100% - 5px);
      overflow: auto;
      padding: 0.75rem 0;


      & > button:not(:last-child) {
        margin-bottom: 1rem;
      }

      & > button {
        width: 100%;
        text-align: left;
        padding: 0 0.75rem;
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: block;
        color: var(--rdr-black);
      }

      .rdrDatePickerSelectCurrentMonth {
        background-color: var(--rdr-black-l-93) !important;
        color: var(--rdr-black);
      }


      &::-webkit-scrollbar {
        min-width: 6px;
        width: 6px;
        padding-right: 2px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        min-height: 60px;
        height: 60px;
        border-radius: 100px;
        background-color: #BDD9EC;
        border: none;
      }

      &::-webkit-scrollbar-button {
        display: block;
        height: 0;
      }
    }
  }

  .rdrDatePickerTimeInput {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 8px;

    svg {
      margin-right: 10px;
    }

    & * {
      fill: var(--rdr-black-l-40);
    }

    & > div {
      width: calc(100% - 34px) !important;
    }
  }

  .rdrCalendarFixed {
    position: fixed;
    left: auto;
    top: auto;
    z-index: 15;
  }
}

