$lineHeight: 38px;
$linePadding: 4px;
$buttonWidth: $lineHeight;
$verticalPadding: 12px;

.FooterWrapper {
  background-color: white;
  padding: 0 0 $verticalPadding 0;

  &__WithoutPropmpter {
    max-height: 294px;
  }

  &_error {
    padding: 0 20px;
    color: var(--red-600);
  }

  &_commentsMode {
    background-color: var(--yellow-100);
  }

  .FileInfoWrapper {
    max-height: 64px;
    overflow-y: auto;
    max-width: 100%;
    padding: 8px 20px 0 20px;
    align-self: start;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .SendMessage {
    background: none;
    border: none;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SendButton {
    img:hover {
      filter: brightness(0.75);
    }
    img:active {
      filter: brightness(0.5);
    }
  }

  .Dropdown {
    margin-right: $linePadding;
    border-radius: 4px;

    .DropdownButton {
      padding-left: 0;
      background: none !important;
      border: none !important;
      color: var(--gray-950);
      margin: 0 !important;
      height: $lineHeight;
      padding: 8px !important;
    }
    .DropdownMenu {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(12, 43, 66, 0.16),
        0px 0px 1px rgba(17, 60, 92, 0.06);
      border-radius: 4px;
      border: none;
      padding: 8px 0 !important;
      top: -8px !important;
      button {
        padding: 8px 4px;
        color: var(--gray-800);

        &.active {
          background: var(--gray-200);
        }
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }

    &.show {
      background-color: var(--gray-300);
    }
  }

  &_input {
    min-height: 24px;
    max-height: 140px;
    width: 100%;
    border: none !important;
    outline: none;
    background-color: transparent;
    display: block;
    resize: none;
    padding: 0 20px 0 20px;
    margin-bottom: $verticalPadding;
    margin-top: $verticalPadding;
    &:focus,
    &:hover {
      border: none !important;
      outline: none;
    }
  }

  .BottomControls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: $lineHeight;
    padding-right: $linePadding;

    #send-message-button {
      margin-left: auto;
      position: relative;
      button {
        cursor: pointer;
      }
    }

    .VerticalLine {
      margin: 0 $linePadding;
      width: 1px;
      height: 100%;
      background: var(--gray-200);
      padding: 4px 0;
    }

    .LeftSide {
      display: flex;
      align-items: center;
      padding-left: $verticalPadding;
      .Prompter {
        position: relative;
        height: $lineHeight;
        display: flex;
        align-items: center;
        padding-left: calc($linePadding * 2);
        margin-bottom: 0;
      }
    }

    .RightSide {
      display: flex;
      button {
        width: $buttonWidth;
      }
    }
  }
}

.ChatFooter_waiting {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 620px) {
    height: 4em;
  }
}

.FileInfo {
  display: flex;
  font-size: 14px;
  align-self: flex-start;
  background: var(--gray-100);
  border-radius: 4px;
  padding: 4.5px 8px;

  &_clearFileButton {
    padding: 0 !important;
  }
  &_Name {
    display: flex;
    margin-right: 0.5em;
    max-width: 250px;

    & span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.CommentMode {
  background: var(--gray-200);
}
