@mixin colorVariant($colorName, $color) {
  @each $intensity, $value in $color {
    .color-#{$colorName}-#{$intensity},
    .#{$colorName}-#{$intensity} {
      color: $value;
    }
  }
}

@mixin bgColorVariant($colorName, $color) {
  @each $intensity, $value in $color {
    .bg-color-#{$colorName}-#{$intensity} {
      background-color: $value;
    }
  }
}
@mixin hoverColorVariant($colorName, $color) {
  @each $intensity, $value in $color {
    .hover-color-#{$colorName}-#{$intensity} {
      &:hover {
        color: $value;
      }
    }
  }
}
@mixin bgHoverColorVariant($colorName, $color) {
  @each $intensity, $value in $color {
    .bg-hover-color-#{$colorName}-#{$intensity} {
      &:hover {
        background-color: $value;
      }
    }
  }
}
