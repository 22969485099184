@use "sass:math";

@import "../functions";


@mixin form-validation-state($state, $color, $icon, $iconDisabled, $iconDark) {
  .#{$state}-feedback {
    display: none;
    width: calc(100% - 15px);
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    color: $color;
    position: absolute; // todo decide what to do with feedbacks
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
    margin-top: .1rem;
    @include font-size($form-feedback-tooltip-font-size);
    line-height: $form-feedback-tooltip-line-height;
    color: color-yiq($color);
    background-color: rgba($color, $form-feedback-tooltip-opacity);
    @include border-radius($form-feedback-tooltip-border-radius);
  }

  $substate: if($state =='warning', 'invalid', 'valid');

  input.form-control {
    .was-validated &:#{$substate}, //todo ломает .was-validated:warning
    &.is-#{$state} {
      border-color: $color;

      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-image: $icon;
        background-repeat: no-repeat;
        background-position: center right $input-height-inner-quarter;
        background-size: $input-height-inner-half $input-height-inner-half;

        &.form-control-sm{
          padding-right: $input-height-sm-inner;
          background-position: center right $input-height-sm-inner-quarter;

          &.positionLeft{
            background-position: center left $input-height-sm-inner-quarter;
          }
        }

        &.form-control-lg{
          padding-right: $input-height-lg-inner;
          background-position: center right $input-height-lg-inner-quarter;

          &.positionLeft{
            background-position: center left $input-height-lg-inner-quarter;
          }
        }

        &.clearable{
          padding-right: $input-height-inner-double;

          &.positionLeft{
            padding-right: $input-height-inner;
          }
        }

        .input-group & {
          background: none;
        }
      }


      &.positionLeft{
        padding-left: $input-height-inner;
        padding-right: $input-padding-x;
        background-position: center left $input-height-inner-quarter;
      }

      &:hover {
        border-color: $color;
      }

      &:focus {
        border-color: $color;
        @if $enable-shadows {
          box-shadow: 0 0 0 $input-focus-width rgba($color, .3);
        }
      }

      &:disabled{
          border-color: $input-border-color;
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }

      & + .clear{
        //right: $input-height-inner;
        margin-left: $input-height-inner-double-negative;
      }

      &.form-control-lg {

        &.positionLeft{
          padding-left: $input-height-lg-inner;
          padding-right: $input-padding-x-lg;
        }

        &.clearable{
          padding-right: $input-height-lg-inner-double;

          &.positionLeft{
            padding-right: $input-height-lg-inner;

            & + .clear{
              margin-left: $input-height-lg-inner-negative;
            }
          }
        }


        & + .clear{
          //right: $input-height-lg-inner;
          margin-left: $input-height-lg-inner-double-negative;
        }
      }

      &.form-control-sm {

        &.positionLeft{
          padding-left: $input-height-sm-inner;
          padding-right: $input-padding-x-sm;
        }

        &.clearable{
          padding-right: $input-height-sm-inner-double;

          &.positionLeft{
            padding-right: $input-height-sm-inner;

            & + .clear{
              margin-left: $input-height-sm-inner-negative;
            }
          }
        }

        & + .clear{
          //right: $input-height-sm-inner;
          margin-left: $input-height-sm-inner-double-negative;
        }
      }

      &.positionLeft + .clear{
        //right: 1px;
        margin-left: $input-height-inner-negative;
      }
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  textarea.form-control {
    .was-validated &:#{$substate},
    &.is-#{$state} {
      border-color: $color;

      @if $enable-validation-icons-textarea {
        padding-right: $input-height-inner;
        background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
      }

      &:focus{
        @if $enable-shadows {
          box-shadow: 0 0 0 $input-focus-width rgba($color, .3);
        }
      }


      &:disabled{
        border-color: $input-disabled-border;
      }

      &.clearable{
        padding-right: $input-height-inner;

        & + .clear{
          //right: $input-height-inner;
          margin-left: $input-height-inner-negative;
        }
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }

      ~ .form-text{
        display: none;
      }

    }
  }

  .custom-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;

      @if $enable-validation-icons {
        padding-right: $custom-select-feedback-icon-padding-right;
        background: $custom-select-background, $icon $custom-select-bg no-repeat math.div($custom-select-feedback-icon-position, $custom-select-feedback-icon-size);
      }

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, .3);
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }


  .form-control-file {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .form-check-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .form-check-label {
        color: $color;
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .custom-control-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-control-label {
        color: $color;

        &::before {
          border-color: $color;
        }
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }

      &:checked {
        ~ .custom-control-label::before {
          border-color: lighten($color, 10%);
          @include gradient-bg(lighten($color, 10%));
        }
      }

      &:focus {
        ~ .custom-control-label::before {
          box-shadow: 0 0 0 $input-focus-width rgba($color, .3);
        }

        &:not(:checked) ~ .custom-control-label::before {
          border-color: $color;
        }
      }
    }
  }

  // custom file
  .custom-file-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-file-label {
        border-color: $color;
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }

      &:focus {
        ~ .custom-file-label {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, .3);
        }
      }
    }
  }

  .form-control.dark {
    ~.#{$state}-feedback {
      color: lighten($color, 20%);

    }
    .was-validated &:#{$substate}, //todo ломает .was-validated:warning
    &.is-#{$state} {
      border-color: lighten($color, 20%);
      &:focus {
        border-color: lighten($color, 20%);
      }
      @if $enable-validation-icons {
        background-image: $iconDark;
      }
    }
  }
}
