.justui_dropdown {
  max-height: 30vw;

  .dropdown-item {
    padding: 8px 20px;
    font-size: 14px;
    line-height: 21px;
    color: var(--gray-950);

    &.disabled,
    &[disabled] {
      cursor: not-allowed !important;
      z-index: 1;
      pointer-events: all;
      color: var(--gray-600);
      &:hover {
        background-color: transparent;
      }
    }
    svg {
      position: relative;
      top: 1px;
      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }

    &:hover {
      background-color: var(--gray-200);
    }
    &:active {
      background-color: var(--primary);
    }

    &.dropdown-item-danger {
      &:hover {
        background-color: var(--red-200);
        color: var(--red-700);
      }

      &:active {
        background-color: var(--red-500);
        color: var(--white);
      }
    }
  }

  .dropdown-header {
    padding: 8px 20px;
    font-size: 14px;
    line-height: 21px;
    color: var(--gray-600);
    border-bottom: 1px solid var(--gray-200);
    font-weight: normal;
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: default !important;
    &:first-child {
      margin-top: 0;
    }
  }

  .dropdown-menu {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }

  .dropright .dropdown-menu {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .dropleft .dropdown-menu {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }

  .dropup .dropdown-menu {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }

  .dropdown-divider {
    border-top-color: var(--gray-200);
  }

  .dropdown-caret {
    margin-left: 10px;
  }

  &.compact {
    .justui_dropdown-toggle {
      line-height: 0;
    }

    .justui_dropdown-menu {
      padding: 0;
    }

    .justui_dropdown-item {
      padding: 0.2rem 0.5rem;
      font-size: 14px;
    }

    .dropdown-divider {
      margin: 0;
      padding: 0;
    }
  }

  &.dark {
    .justui_dropdown-menu {
      background-color: #273238;
      box-shadow: 0px 0px 1px rgba(17, 60, 92, 0.06), 0px 6px 12px rgba(12, 43, 66, 0.16),
        0px 0px 2px rgba(0, 0, 0, 0.1);
    }
    .dropdown-divider {
      border-color: #1f282d;
    }

    .justui_dropdown-item {
      color: #e7ebed;
    }

    .justui_dropdown-item.disabled {
      color: #999;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: #1f282d;
      color: white;
    }
    .dropdown-header {
      border-color: #1f282d;
    }
  }
}

.justui_popover-body {
  .justui_dropdown {
    overflow: auto;
  }
}
