@import '../components/Blocks.scss';

.Layout {
  margin: 8px 0;
  background: var(--white);
  border-radius: 4px;
  padding: 16px;
  width: 100%;
}

.Charts {
  height: 280px;
  position: relative;
  width: 100%;
}

.Section {
  margin: 24px 0 8px 0;

  & > h3 {
    margin-bottom: 8px;
  }
}

.List {
  height: 400px;
  max-width: 800px;

  ul {
    list-style-type: none;
    padding: 0;
    overflow: auto;
  }

  li {
    margin-bottom: 8px;
    background: var(--white);
    border-radius: 4px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .AnalyticActionPanel_page_link {
    height: auto !important;
  }
}

.Blocks_Wrapper {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1920px) {
    flex-wrap: nowrap;

    div:first-child {
      margin-right: 1em;
    }
  }
}

.DropdownButton {
  margin-top: 1em;
  padding: 0.25em 0.5em !important;
  background-color: #f5f4fe !important;
  border: 1px solid #e3defb !important;
  border-radius: 4px;
  color: var(--gray-800) !important;
}

.DropdownMenu {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 1px rgba(17, 60, 92, 0.06);
  border-radius: 3px !important;
  padding: 1em !important;
  border: none !important;
  label {
    margin-bottom: 0.5em !important;
  }

  .ButtonGroup {
    min-width: 300px;
    text-align: right;
    margin-top: 1em;
    button {
      margin-left: 0.5em;
    }
  }
}

.Filters {
  display: flex;
  margin-bottom: 16px;
  .ChartTypeSelector {
    max-width: 260px;
    margin-right: 8px;
  }
  .IntervalTypeSelector {
    max-width: 108px;
  }

  svg {
    height: 1rem !important;
    width: 1rem !important;
  }
}