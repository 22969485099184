@import './Tabs/Main/Main.scss';

.Statistics {
  padding: 1.5rem;
  max-height: calc(100vh - 3rem);
  position: relative;
  width: 100%;
  overflow: auto;
  @media (max-width: 620px) {
    padding: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    & > h2 {
      margin-left: 1rem;
    }
  }
  .Statistics_tabs {
    margin-top: 24px;
    width: 100%;
    height: 100%;
    @media (max-width: 620px) {
      max-width: 100vw;
      height: calc(100% - 2rem);
      padding: 0;
      & > * {
        padding-left: 1rem;
        padding-right: 1rem;
        &:not(:first-child) {
          width: 100vw;
        }
      }
    }
    &_description {
      max-width: 800px;
    }
    .Statistics_tabs_navigator {
      max-width: 100vw;
      @media (max-width: 620px) {
        padding: 0 1rem;
      }
    }
  }
  h1 {
    color: var(--gray-950);
  }
}
.CreateOperatorModal_container {
  .just-select-selected-item {
    color: var(--gray-800);
  }
}
