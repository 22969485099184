@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';

.force-overflow-hidden {
  overflow: hidden;
  > * {
    overflow: hidden;
  }
}

.just-select-wrapper {
  position: relative;
  width: 295px;
  z-index: 550;
  &.fullWidth {
    width: 100%;
    .just-select-container {
      width: 100%;
    }
  }

  &.opened {
    .just-select-list {
      display: block;
      z-index: 1401;
    }
    ~ .just-select-backdrop {
      display: block;
    }
  }

  &.blur_inactive {
    .single,
    .multiple {
      background-color: var(--gray-100);
    }
  }

  & .hint {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    white-space: break-spaces;
    color: var(--gray-600);
  }

  .hiddenJustSelectInput {
    position: absolute;
    left: -10000px;
    top: -10000px;
    width: 0;
    height: 0;
  }

  .just-select-wrapper_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }
}

.just-select-container {
  border-radius: 3px;
  width: 100%;
  border: 1px solid var(--grey-300);
  min-height: 36px;
  position: relative;

  &.disabled {
    cursor: not-allowed;
    .single,
    .multiple {
      &::placeholder {
        color: var(--gray-300);
      }
      color: var(--gray-300);
      cursor: not-allowed;
    }
    .just-select-container-open {
      cursor: not-allowed;
      svg {
        color: var(--gray-300);
      }
    }
    .just-select-selected-item-cleanup {
      cursor: not-allowed;
      svg {
        color: var(--gray-300);
      }
    }
  }

  &.multiple {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    padding: 6px 36px 0 7px;

    &.closeable {
      padding-right: 62px;
    }
  }

  &.focused {
    border-color: var(--primary);
  }

  & .leftIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    line-height: inherit;
    width: 36px;
    height: 100%;

    & svg {
      color: var(--gray-600);
    }
  }

  &.sm {
    .single,
    .multiple {
      padding: 4px 38px 4px 11px;
    }
  }
  &.lg {
    & .single,
    .multiple {
      font-size: 16px;
      line-height: 28px;
    }

    .single,
    .multiple {
      height: 44px;
      padding: 8px 54px 8px 15px;
      ~ .just-select-container-open {
        width: 46px;
      }
    }
  }

  .single {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .single,
  .multiple {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 36px 6px 12px;
    border: none;
    outline: none;
    box-shadow: none;
    letter-spacing: 0.02em;
    border-radius: 3px;

    &.withLeftIcon {
      padding-left: 36px !important;
    }

    &.multiple {
      display: flex;
      height: 26px;
      flex-grow: 1;
      width: 0;
      padding: 0;
      margin-bottom: 7px;
    }
    &:not(:focus):hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.just-select-selected-item {
  display: flex;
  flex-grow: 0;
  border-radius: 3px;
  border: 1px solid $grey-800;
  background: $grey-600;
  margin-bottom: 6px;
  margin-right: 5px;
  color: white;
  align-items: center;

  svg {
    path {
      fill: currentColor;
    }
  }

  &-label-name {
    font-size: 12px;
    letter-spacing: 0.02em;
    line-height: 18px;
    padding: 3px 4px 3px 8px;
    display: block;
    max-width: 20ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-delete {
    cursor: pointer;
    line-height: 16px;
  }
}

.just-select-selected-item-cleanup,
.just-select-container-open {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  line-height: inherit;
  width: 2rem;
  height: 100%;
  svg {
    color: var(--gray);
    pointer-events: none;
    display: flex;
  }
}

.just-select-list {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 8px 0;
  max-height: 285px;
  background: $white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 3px;
  overflow: hidden;

  &.disabled {
  }
  &.opened {
    display: block;
    z-index: 1401;
  }

  &:not(.fixed) {
    &.top {
      bottom: 100%;
      margin-bottom: 4px;
    }
  }
  &.bottom {
    top: 100%;
    margin-top: 4px;
  }

  .relativeWrapper {
    position: relative;
    max-height: 269px;
    //height: 269px;
  }
  .scrollbar-container {
    padding-right: #{$modal-header-padding-x * 0.5};
    max-height: 269px;

    & .ps__rail-y {
      width: 0.875rem;
      background-color: transparent;
      cursor: pointer;
      border-left: 1px solid transparent;

      &:hover {
        border-color: lighten($black, 90%);
        background-color: lighten($black, 97%);
      }

      & .ps__thumb-y {
        left: 1px;
        right: auto;
        background-color: #cfd8dc;
        transition: background-color 0.2s linear, width 0.2s ease-in-out, left 0.2s ease-in-out;
      }

      &:hover,
      &.ps--clicking {
        background-color: lighten($black, 97%);

        & .ps__thumb-y {
          background-color: var(--primary);
        }
      }
    }
  }

  .just-select-item {
    display: block;
    width: 100%;
    padding: 8px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 21px;

    &.focused {
      background: rgba($grey-400, 0.5);
    }

    &.active {
      background-color: var(--primary);
      color: $white;
    }

    &.clickable {
      cursor: pointer;
    }
    &.disabled {
      cursor: not-allowed;
      color: var(--gray-300);
      &.focused {
        background: var(--gray-100);
      }
      &.active {
        background-color: rgba($primary, 0.3);
        color: $white;
      }
    }
  }
}

.just-select-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 500;
  &.opened {
    display: block;
  }
}

.just-select-item[data-test-id='just-select-item__Nothing found'] {
  cursor: default;
}

.just-select-container.error {
  border-color: var(--danger);
}

.xs.just-select-wrapper {
  font-size: 12px;

  .just-select-container {
    min-height: 20px;
  }

  .just-select-container .single,
  .just-select-container .multiple {
    height: 100%;
    padding-left: 6px;
    padding-right: 24px;
  }
}

.xs.just-select-list .just-select-item {
  padding-top: 0;
  padding-bottom: 0;
  white-space: pre-wrap;
  font-size: 12px;
}
