.OperatorPlace_container {
  --blue-blue-100: #f2f5fb;

  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 411px auto minmax(200px, 616px);
  grid-template-rows: 100%;
  background-color: var(--blue-blue-100);
  position: relative;

  @media (max-width: 1280px) {
    grid-template-columns: 411px auto 300px;
  }
  @media (max-width: 620px) {
    grid-template-columns: 100% auto 200px;
    display: flex;
    flex-direction: column;
  }
  & > * {
    height: 100%;
  }

  &.WithBanner > * {
    height: calc(100% - 45px);
  }

  &.HideRightPanel {
    grid-template-columns: 411px auto;
  }

  .OperatorPlace_nochoosenInfo {
    background-color: var(--gray-100);
    position: absolute;
    grid-column: 2/-1;
    grid-row: 1/-1;
    z-index: 100;

    height: 100%;
    width: 100%;

    padding-left: var(--left-size);

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .justui__tab-name {
    width: 100%;
  }
  & > .just-ui-spinner {
    position: absolute !important;
    grid-column: 2/-1;
    grid-row: 1/-1;

    height: 100%;
    width: 100%;
  }
}

.UsersList {
  height: 100%;
  width: 100%;
  list-style-position: inside;
  -webkit-padding-start: 0;

  position: absolute;
  grid-row: -2/-1;
  grid-column: 1/-1;

  overflow: auto;
  padding-bottom: 48px;

  p {
    margin-bottom: 0;
  }

  &_placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  & > li {
    width: 100%;
    margin-bottom: 0.25rem;
    list-style-type: none;
    padding: 8px 16px;
    border-radius: 4px;

    &:hover {
      background-color: var(--indigo-100);
    }
    cursor: pointer !important;
    * {
      cursor: pointer !important;
    }
    .DescriptionChat {
      display: flex;
      justify-content: space-between;

      &_Username {
        color: var(--gray-600);
        max-width: 300px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .UsersList_User {
      &_name,
      &_time {
        color: var(--gray-600);
      }
      &_unreadedMessages {
        grid-column: 2/-1;
        grid-row: 2/-1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        & > div {
          height: 21px;
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 4px;
          border-radius: 2px;

          background-color: var(--primary);
          text-align: right;
          color: var(--white);
        }
      }
      &_time {
        grid-column: 2/-1;
        grid-row: 1/2;
        text-align: right;
      }
      &_lastMessage {
        grid-column: 1/-1;
        grid-row: 2/-1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        max-width: 100%;

        & > * {
          flex-shrink: 0;
          max-width: calc(100% - 1.5rem);
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &_unanswered {
          width: 12px;
          height: 12px;
          border-radius: 6px;
          background-color: var(--red-600);
        }
      }
    }
    &.UsersList_User_active {
      background-color: var(--indigo-600);
      small {
        color: var(--indigo-200);
      }
      .UsersList_User {
        &_lastMessage {
          color: var(--white);
          * {
            color: var(--white);
          }
        }
      }
    }
  }
  &_loadMore {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.Tabs {
  margin-bottom: 16px !important;
}
