.Blocks {
  margin-top: 8px;
  background: var(--white);
  border-radius: 4px;
  padding: 0;
  width: 100%;
  display: flex;

  .Block {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 16px;

    h3 {
      margin-top: 8px;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }


    p {
      margin-bottom: 0;
    }

    &:not(:first-child) {
      border-left: 1px solid var(--gray-200);
    }
  }
}
