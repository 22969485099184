// Bootstrap overrides

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark: #263238;
$gray: var(--primary-500);
$gray-light: #b0bec5;
$gray-lighter: #cfd8dc;
$gray-lighter2: #dfe9ed;
$gray-lightest: #eceff1;

$brand-primary: #605fe3;
$brand-success: #3a7f3a;
$brand-info: #0d1822;
$brand-warning: #e6c323;
$brand-danger: #c14646;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: true;

// Body
//
// Settings for the `<body>` element.

$body-bg: #e4e5e6;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

// Breadcrumbs

$breadcrumb-bg: #fff;

// Cards

$card-border-color: $gray-lighter;
$card-cap-bg: $gray-lightest;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-lighter;
$dropdown-divider-bg: $gray-lightest;

// Buttons

$btn-secondary-border: $gray-light;

// Progress bars

$progress-bg: $gray-lightest;

// Tables

$table-bg-accent: $gray-lightest;
$table-bg-hover: $gray-lightest;

$btn-primary-bg: $brand-primary;
$btn-primary-border: $btn-primary-bg;
