.SmoothSpinner {
  height: 100%;
  width: 100%;
  position: absolute;
  grid-column: 2/-1;
  grid-row: 1/-1;

  height: 100%;
  width: 100%;
  animation-fill-mode: forwards;
  &_toShow {
    animation: smoothSpinner_toShow;
  }
  &_toHide {
    animation: smoothSpinner_toHide;
  }
}

@keyframes smoothSpinner_toShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes smoothSpinner_toHide {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}

.Background {
  width: 100%;
  height: 100%;
  opacity: 0.48;
  background: black;
  position: absolute;
  z-index: 1000;
}

.Banner {
  position: relative;
  height: 45px;
  background: #fff4dc;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  .justui__icon-wrapper {
    position: absolute;
    right: 14px;
    top: 14px;
  }
}
