$base-size: 4px;

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}
.ai-start {
  align-items: flex-start;
}
.ai-center {
  align-items: center;
}

.jc-end {
  justify-content: flex-end;
}
.jc-start {
  justify-content: flex-start;
}
.jc-center {
  justify-content: center;
}

.fullSize {
  width: 100%;
  height: 100%;
}

.font-color-normal {
  color: var(--gray-800);
}

.font-color-light-gray {
  color: var(--gray-600);
}

.font-color-bright {
  color: var(--gray-950);
}

.font-color-danger {
  color: var(--danger);
}

.font-color-primary {
  color: var(--primary);
}

.font-color-secondary {
  color: var(--secondary);
}

.font-bold {
  font-weight: bold;
}

.font-link {
  color: var(--primary);
  border-bottom: 1px dashed var(--primary);
}

.gray-select {
  .just-select-container {
    background-color: var(--gray-100);
    input {
      background-color: var(--gray-100);
      min-height: 36px;
    }
  }
  .just-select-container:not(.error) {
    background-color: var(--gray-100);
    border: 1px solid var(--gray-200);
  }
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-12 {
  font-size: 0.75rem;
}

.font-size-16 {
  font-size: 1rem;
}

.font-size-20 {
  font-size: 1.25rem;
}

.line-height-24 {
  line-height: 24px;
}
.line-height-21 {
  line-height: 21px;
}
.line-height-20 {
  line-height: 20px;
}
.line-height-18 {
  line-height: 18px;
}
.line-height-16 {
  line-height: 16px;
}
.hide-on-empty {
  &:empty {
    display: none;
  }
}
$parametors: (
  gap: '.gap',
  width: '.width',
  height: '.height',
  padding: '.padding',
  padding-top: '.padding-top',
  padding-bottom: '.padding-bottom',
  padding-left: '.padding-left',
  padding-right: '.padding-right',
  margin: '.margin',
  margin-top: '.margin-top',
  margin-bottom: '.margin-bottom',
  margin-left: '.margin-left',
  margin-right: '.margin-right',
  border-radius: '.border-radius',
  top: '.top',
  bottom: '.bottom',
  right: '.right',
  left: '.left',
);
$borders: (
  border: '.border',
  border-top: '.border-top',
  border-bottom: '.border-bottom',
  border-left: '.border-left',
  border-right: '.border-right',
);

$parametorSizes: (
  0x: 0,
  0-5x: 2,
  1x: 4,
  2x: 8,
  3x: 12,
  4x: 16,
  5x: 20,
  6x: 24,
  7x: 32,
  8x: 36,
);

@each $parametor, $parametor-value in $parametors {
  @each $size, $size-value in $parametorSizes {
    #{$parametor-value}-#{$size} {
      #{$parametor}: #{$size-value}px;
    }
  }
}
@each $parametor, $parametor-value in $parametors {
  @for $size-value from 1 through 60 {
    #{$parametor-value}-#{$size-value} {
      #{$parametor}: #{$size-value}px;
    }
  }
}
@for $size-value from 1 through 9 {
  .font-weight-#{$size-value * 100} {
    font-weight: #{$size-value * 100};
  }
}
@for $size-value from 0 through 60 {
  .padding-x-#{$size-value} {
    padding-left: #{$size-value}px;
    padding-right: #{$size-value}px;
  }
}
@for $size-value from 0 through 60 {
  .padding-y-#{$size-value} {
    padding-top: #{$size-value}px;
    padding-bottom: #{$size-value}px;
  }
}
@for $size-value from 0 through 16 {
  .border-radius-#{$size-value} {
    border-radius: #{$size-value}px;
  }
}

@include colorVariant('gray', $grays);
@include bgColorVariant('gray', $grays);
@include hoverColorVariant('gray', $grays);
@include bgHoverColorVariant('gray', $grays);
@include colorVariant('grey', $greys);
@include colorVariant('blue', $blues);
@include colorVariant('indigo', $indigos);
@include colorVariant('purple', $purples);
@include colorVariant('red', $reds);
@include colorVariant('yellow', $yellows);
@include colorVariant('green', $greens);
@include colorVariant('orange', $oranges);

@include borderColorVariant($borders, 'gray', $grays);
@include borderColorVariant($borders, 'grey', $greys);

.cursor-pointer {
  cursor: pointer;
}

.paper-block {
  background-color: var(--white);
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.16),
    0px 1px 3px rgba(12, 43, 66, 0.06),
    0px 0px 1px rgba(17, 60, 92, 0.06);
  border-radius: 4px;
  padding: 1rem;
}

.form-control:focus {
  box-shadow: none !important;
}
.pre-line {
  white-space: pre-line;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
