@mixin main {
  padding: 8px;
  border-radius: 8px;
  background-color: var(--blue-200);
  svg {
    width: 11px;
  }
}

.PrompterWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background-color: var(--blue-100);
  width: 100%;

  #close-prompter-button {
    @include main;
    padding: 0;

    height: 28px;
    width: 28px;
    align-self: flex-end;
    position: absolute;
    top: -36px;
  }

  #sent-prompter-button {
    @include main;
    width: 28px;
    margin-left: 8px;
  }

  &.NoContent {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;

    #close-prompter-button {
      position: relative;
      top: 0;
      height: 37px;
      width: 37px;
      align-self: center;
      padding: 0.5rem 0.625rem;
    }

    .PrompterMessages {
      width: 100%;
      margin-right: 8px;
    }
  }
}

.Prompter {
  display: flex;
  margin-bottom: 8px;
  p {
    margin-bottom: 4px;
  }
  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__info {
    @include main;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
    &__percent {
      color: var(--gray-600);
      width: 31px;
      display: inline-block;
      margin: 0;
      margin-right: 8px;
    }
  }
}

.PrompterMessages {
  max-height: 60vh;
  overflow: auto;
}

.Attachments {
  display: flex;
  flex-wrap: wrap;
  .PrompterImage {
    height: 42px;
    width: 42px;
    margin-top: 8px;
    background-size: cover;
    border-radius: 4px;
    margin-right: 6px;
    background-color: var(--blue-500);
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
  }
  .VideoWrapper {
    video {
      height: 42px;
      width: 42px;
      background-size: cover;
      object-fit: cover;
    }
    span {
      position: absolute;
    }
    &__percent {
      color: var(--gray-600);
      width: 31px;
      display: inline-block;
      margin: 0;
      margin-right: 8px;
    }
  }

  .ButtonsWrapper {
    width: 100%;
    .Button {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  p {
    color: var(--white);
    font-size: 14px;
    margin-bottom: 0;
  }
}
