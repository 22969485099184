.FramesList {
  &_container {
    margin-top: 20px;
    width: 100%;
  }

  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: white;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 4px;
    button {
      margin-left: 4px;
    }
  }

  &_group {
    border-radius: 2px;
    margin-right: 4px;
    padding: 2px 4px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__description {
      display: flex;
      flex-direction: column;
    }

    &__groups {
      display: flex;
      flex-wrap: wrap;
      max-width: 432px;
      div {
        margin-top: 4px;
        margin-right: 4px;
      }
    }
  }
}
.FrameForm {
  input,
  textarea {
    background-color: var(--gray-100) !important;
    border-color: var(--gray-200) !important;
  }
}
