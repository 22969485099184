@mixin container {
  margin-top: 24px;
  background: white;
  padding: 16px;
  width: 540px;
}

.Prompters_container {
  @include container;

  button {
    width: 80px;
  }

  div input {
    background-color: var(--gray-100);
    border-color: var(--gray-200);
    height: 30px;
  }

  &__item {
    @include container;

    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0;
    }

    button:first-child {
      margin-right: 8px;
    }

    &__description {
      p small {
        color: var(--gray-600);
      }
    }

    &__groups {
      display: flex;
      flex-wrap: wrap;
      max-width: 432px;
      div {
        margin-top: 4px;
        margin-right: 4px;
      }
    }
  }
}
