hr.transparent {
  border-top: 1px solid transparent;
}

.gap-8 {
  gap: 0.5rem;
}
.gap-16 {
  gap: 1rem;
}
