// IE10&11 Flexbox fix
@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

.app-footer {
  flex: 0 0 $footer-height;
}

//
// Aside Menu
//
.aside-menu-hidden {
  .aside-menu {
    margin-right: -$aside-menu-width;
  }
}

.aside-menu-fixed {
  .aside-menu {
    position: fixed;
    right: 0;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }

  .main,
  .app-footer {
    margin-right: $aside-menu-width;
  }

  &.aside-menu-hidden {
    .main,
    .app-footer {
      margin-right: 0;
    }
  }
}

.aside-menu-off-canvas {
  .aside-menu {
    position: fixed;
    right: 0;
    z-index: $zindex-sticky - 1;
    height: 100%;

    .tab-content {
      height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
    }
  }
}

//
// Footer
//
.footer-fixed {
  .app-footer {
    position: fixed;
    bottom: 0;
    z-index: $zindex-sticky;
    width: 100%;
  }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
// .main,
.aside-menu {
  transition-duration: $layout-transition-speed, $layout-transition-speed, $layout-transition-speed,
    $layout-transition-speed;
  transition-property: margin-left, margin-right, width, flex;
}
