@use "sass:math";

.row.row-equal {
  padding-right: ($grid-gutter-width-base * 0.25);
  padding-left: ($grid-gutter-width-base * 0.25);
  margin-right: math.div($grid-gutter-width-base, -2);
  margin-left: math.div($grid-gutter-width-base, -2);

  [class*='col-'] {
    padding-right: ($grid-gutter-width-base * 0.25);
    padding-left: ($grid-gutter-width-base * 0.25);
  }
}
