.justui_tabs.nav {
  border-bottom: 1px solid #ededed;
  margin-bottom: 1.5rem;

  .nav-item {
    text-align: center;
    margin-bottom: -1px;
    border-bottom: 1px solid var(--grey-200);
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid var(--grey-400);
    }
    &:focus {
      border-bottom: 1px solid transparent;
      z-index: 1000;
      outline: none;
      box-shadow: 0 0 3px 0px var(--primary);
      border-radius: 3px 3px 0 0;
    }
    &.active {
      cursor: default;
      border-bottom: 2px solid var(--primary)!important;
    }

    .nav-link {
      letter-spacing: 0.02em;
      color: var(--grey);
      display: flex;
      align-items: center;

      &:hover {
        color: var(--primary);
      }

      &:focus,
      &.active {
        color: var(--grey-800);
      }


      &.disabled {
        color: var(--grey-300);
        .icon-box {
          color: var(--grey-300);
        }
      }

      .left-icon {
        margin-right: 7px;
      }

      .right-icon {
        margin-left: 7px;
      }
    }
  }

  &.justui_tabs-bold {
    font-weight: bold;
  }

  &.justui_tabs-bordered {
    .nav-item {
      outline-width: thin;
      outline-color: var(--black-light);
      outline-style: solid;
    }
  }

  a {
    line-height: 18px;
  }

  &.justui_tabs-compact {
    margin-bottom: unset;
    border-bottom: unset;
    font-size: 0.75rem;
    font-weight: 700;

    a {
      line-height: 18px;
    }

    .justui__tab-name {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .nav-item {
      border-bottom: unset;
      margin-bottom: unset;
      background-color: var(--grey-200);
      &:hover:not(.disabled) {
        background-color: transparent;
      }
    }

    .nav-item.active:not(.disabled) {
      background-color: transparent;
    }

    &.justui_tabs-dark {
      .nav-item.active {
        background-color: #000000;
      }
    }

    .nav-link {
      padding: 3px 8px;
      &:hover {
        color: #000000;
      }
      &.active {
        color: var(--grey-900);
      }
    }
    .close-icon {
      font-size: 9px;
    }
    .icon-box {
      color: var(--grey-600);
      &:hover {
        color: var(--grey-400);
      }
      svg {
        margin-left: 7px;
      }
    }
  }

  &.justui_tabs-dark {
    color: var(--grey-400);

    .nav-item {
      background-color: var(--grey-900);
      color: var(--grey-400);

      outline-color: var(--greyblue-dark);
      border-bottom: 1px solid var(--grey-950);
      &:not(.active):hover {
        background-color: var(--grey-950);
      }
    }

    .nav-link {
      color: var(--grey-400);
      &.active {
        color: var(--grey-100)!important;
      }
      &:hover {
        color: var(--grey-400)!important;

      }
    }

    &.disabled {
      color: var(--grey);
    }
  }

}
