$main-min-width: 720px;

.nowrap {
  white-space: nowrap;
}

.ant-select-dropdown-menu-item-group-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
}

.card-block {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
}

html,
body,
.app {
  height: 100vh;
  width: 100vw;
  min-width: 100vw;
  margin: 0;
}

body.editor-page {
  overscroll-behavior: none;
}

.logo {
  display: flex;

  svg {
    height: 70px;
    width: 70px;
    display: block;
  }
}

.custom-label {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 8px;
  font-size: 1rem;
  z-index: 5;
  cursor: pointer;
}

.collapse-content__action {
  display: block;
  cursor: pointer;

  .fa {
    float: right;
  }
}

.collapse-content__action {
  display: block;
  cursor: pointer;

  .fa {
    float: right;
  }
}

.react-select,
.header-select {
  &__dropdown-indicator,
  &__multi-value__remove,
  &__clear-indicator {
    cursor: pointer;
  }

  & > div.react-select__control,
  & > div.header-select__control {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: none;
    border-radius: 0.25rem;

    &:focus-within {
      border-radius: 0.25rem;
      border-color: var(--primary);
      box-shadow: 0 0 0 1px var(--primary);
    }
  }

  .removed-bot-value {
    background-color: white;
    border: 1px dotted rgba(0, 0, 0, 0.15);
  }

  .removed-bot-option {
    color: rgba(0, 0, 0, 0.15);
  }

  .option__channel-all + div:not(.option__channel-all) {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 8px;
  }
}

.ant-calendar-picker {
  width: 100%;

  &:hover {
    .ant-calendar-picker-input.ant-input {
      border-color: var(--primary);
    }
  }

  &-icon {
    z-index: 0;
  }
}

.ant-calendar-picker-input.ant-input {
  display: block;
  width: 100%;
  padding: 7px 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: var(--primary-500);
  background-color: var(--white);
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  height: 38px;
}

.copy-refInput {
  position: absolute;
  top: -10000px;
  left: -10000px;
  visibility: visible;
}

.btn-outline-default {
  border: 1px solid $gray-lighter;
  color: $brand-primary;
  background-color: $white;
}

.dark-card {
  background-color: $gray-lighter2;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.form-control.error {
  border-color: var(--danger);
}

.form-control-label {
  line-height: 1;
}

.input-group-sm-btn {
  min-width: 30px;
}

header.navbar .navbar-brand {
  height: auto;
  border-bottom: 0;
  display: flex;
  text-decoration: none;

  img {
    margin-right: 8px;
  }
}

.project__selector {
  display: flex;
  align-items: center;

  a {
    color: var(--primary);
  }

  &-separator {
    margin-left: 8px;
    margin-right: 8px;
    color: var(--gray-300);
  }
}

header.navbar .nav-item.ml-4 {
  margin-left: 1.5rem !important;
}

.header__user {
  min-width: 112px;
  button {
    padding-left: 8px;
    padding-right: 8px;
    margin: 0 8px;
  }
  a {
    margin: 0 16px;
  }
}

.main-container {
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
  padding: 0;
  width: 100%;

  & > div:not(.just-ui-spinner) {
    margin: 30px;
    min-width: $main-min-width;
  }

  &.has-white-background {
    background-color: white;
  }
}

.Select-control {
  border-radius: 0 !important;
  border-color: rgba(0, 0, 0, 0.15) !important;
}

.select-headline {
  display: inline-block;

  .select-headline {
    &__control {
      background: transparent;
      box-shadow: none;
      border: 0;
    }

    &__value-container {
      padding: 0;
      line-height: 1;
    }

    &__single-value {
      position: static;
      color: var(--primary);
      font-size: 1.4rem;
      transform: none;
      line-height: 1.3;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      padding: 0;
      cursor: pointer;
    }

    &__input {
      input {
        width: 0 !important;
      }
    }

    &__menu {
      width: 400px;
      border: 0;
      z-index: 11;
    }

    &__option {
      cursor: pointer;
    }
  }
}

.project-select__header {
  width: 180px;

  .header-select__placeholder,
  .header-select__single-value {
    top: calc(50% - 1px);
  }

  .header-select__dropdown-indicator,
  .header-select__clear-indicator {
    cursor: pointer;
  }
}

.buttons-bar {
  .btn {
    margin-left: 10px;
    margin-right: 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.page-link,
.pagination-datatables li a,
.pagination li a {
  padding: 0.25rem 0.5rem;
}

.btn-xs,
.btn-group-xs > .btn {
  line-height: 1.25;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.bordered {
  border: 1px solid $border-color;
}

.break-word {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

.badge.wrap {
  white-space: normal;
}

.dropdown-item {
  .nav-dropdown-toggle {
    &:before {
      position: absolute;
      top: 0.96875rem;
      right: 1rem;
      display: block;
      width: 0.875rem;
      height: 0.875rem;
      padding: 0;
      font-size: 0.875rem;
      line-height: 0.65625rem;
      text-align: center;
      content: '\2039';
      transition: 0.3s;
    }
  }

  &.open {
    .nav-dropdown-toggle {
      &:before {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
    }

    ul {
      max-height: 1000px;
      margin-top: 10px;
    }
  }

  ul {
    max-height: 0;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    transition: max-height 0.3s ease-in-out;

    li.nav-item {
      padding: 0;
      list-style: none;
      position: relative;
      margin: 0;
      transition: background 0.3s ease-in-out;

      .nav-link {
        color: $gray-dark;
        display: block;
        padding: 0.75rem 1rem;
        text-decoration: none;
        background: transparent;
        text-align: left;
      }
    }
  }
}

.dropdown-item {
  a,
  a.nav-link {
    color: $gray-dark;

    &:hover,
    &:focus,
    &:active {
      color: $gray-dark;
      text-decoration: none;
    }
  }
}

.sidebar {
  background: var(--gray-900);
  color: var(--gray-300);
  letter-spacing: 0.02em;
  width: $sidebar-width;
  display: flex;
  flex-direction: column;

  .tariff-box {
    width: 100%;
  }

  .nav-dropdown-items {
    .nav-item .nav-link {
      padding: 8px 8px 8px 40px !important;
      span {
        font-weight: normal;
      }
      &:hover {
        color: var(--primary) !important;
      }
      &.active {
        &:after {
          height: 32px !important;
        }
      }
    }
  }
  &-expand-button {
    margin-top: auto;
  }
  &-tooltip {
    display: none;
    position: fixed;
    left: 58px;
    background-color: var(--gray-800);
    margin-top: -20px;
    z-index: 20;
    font-size: 12px;
    padding: 4px 8px;
    font-weight: normal;
    border-radius: 3px;
    &:before {
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
      left: -5px;
      top: 5px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: var(--gray-800);
    }
  }
  &.minimized {
    .nav-dropdown {
      position: relative;
      &.open {
        .nav-dropdown-toggle {
          background-color: var(--gray-900);
        }
        .sidebar-tooltip {
          display: none !important;
        }
      }
      .nav-dropdown-items {
        position: fixed;
        left: 56px;
        margin-top: -40px;
        z-index: 20;
        box-shadow: 0px 0px 1px rgba(17, 60, 92, 0.06), 0px 6px 12px rgba(12, 43, 66, 0.16),
          0px 0px 2px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        .nav-item .nav-link {
          padding-left: 20px !important;
          padding-right: 20px !important;
          &:hover {
            padding-left: 28px !important;
            padding-right: 12px !important;
          }
          span {
            display: inline-block;
            .badge {
              display: none;
            }
          }
        }
      }
    }
    .sidebar-nav {
      width: unset;
    }
    .nav {
      width: unset;
      .nav-item {
        width: 100%;
        transition: none !important;
        ul {
          transition: none !important;
        }
        .nav-link.nav-dropdown-toggle {
          &:before {
            display: none;
          }
        }
        &:hover {
          .sidebar-tooltip {
            display: block;
            z-index: 10000;
          }
        }
      }
    }
    max-width: $sidebar-compact-width;
    .sidebar-tariff-data,
    .tariff-box,
    span {
      display: none;
    }

    .visible_icon {
      display: inline;
    }
  }

  .justui_icon {
    margin-right: 8px;
    font-size: 16px;
  }
  .nav .nav-item {
    .nav-link {
      color: var(--gray-300);
      font-weight: bold;
      line-height: 16px;
      &.nav-dropdown-toggle {
        &::before {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
          font-size: 22px;
          top: 12px;
        }
      }
      &:hover {
        background-color: var(--gray-900) !important;
      }
      &.active {
        background-color: var(--gray-900) !important;
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 40px;
          width: 4px;
          background-color: var(--primary);
        }
      }
    }
    &.open {
      background-color: unset !important;
      .nav-dropdown-items {
        background-color: var(--gray-900) !important;
      }
      > .nav-link.nav-dropdown-toggle::before {
        -webkit-transform: rotate(-270deg) !important;
        transform: rotate(-270deg) !important;
      }
    }
    &.nav-dropdown {
      &.open {
        & > .nav-link {
          &.active {
            &:after {
              height: 0;
              width: 0;
            }
          }
        }
      }
    }
  }

  &.unprojected {
    .sidebar-nav {
      &::-webkit-scrollbar-track {
        background-color: var(--primary);
        border-right: 1px solid var(--primary);
        border-left: 1px solid var(--primary);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--gray-500);
      }
    }

    .nav .nav-item {
      &.nav-dropdown.open {
        background: rgba(0, 0, 0, 0.05);

        a.nav-link,
        .navbar a.dropdown-toggle {
          //color: $gray-dark;
        }
      }

      .nav-link,
      .navbar .dropdown-toggle {
        //color: $gray-dark;

        &.active {
          background: rgba(0, 0, 0, 0.1);
        }

        i {
          //color: $gray;
        }
      }
    }
  }
}

.navbar-icon {
  font-size: 1.5em;
  vertical-align: middle;
  &-sm {
    font-size: 1rem;
  }
  &-gray {
    color: var(--gray) !important;
  }
}

.collapsible-content {
  background-color: var(--white);
  border: 1px solid $gray-lighter;
  position: relative;
  margin-bottom: 1.5rem;

  &-headline {
    cursor: pointer;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: $gray-lightest;
    border-bottom: 1px solid $gray-lighter;
  }

  &-body {
    padding: 1.25rem;
  }

  &.collapsed {
    .collapsible-content-body {
      display: none;
    }
  }
}

.form-group {
  .ant-input,
  .ant-select-selection {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.25;
    color: var(--primary-500);
    background-color: var(--white);
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    height: auto;

    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    &:focus {
      color: var(--primary-500);
      background-color: var(--white);
      border-color: var(--primary-500);
      outline: none;
    }

    &::placeholder {
      color: var(--gray-300);
      opacity: 1;
    }

    &:disabled {
      background-color: var(--gray-300);
      opacity: 1;
    }
  }

  .ant-select-selection {
    &__rendered {
      line-height: inherit;
    }

    &__choice__remove {
      line-height: 1.5;
      margin-top: 2px;

      svg {
        display: none;
      }

      & > * {
        line-height: 1.3;
      }
    }

    &__choice__content {
      line-height: 1.5;
    }

    &--multiple {
      min-height: 0;
      & > ul > li,
      .ant-select-selection__rendered > ul > li {
        height: 1.5em;
        line-height: 1.5;
      }
    }
  }
}

.anticon-check,
.ant-select-arrow,
.ant-time-picker-icon {
  cursor: pointer;

  svg {
    display: none !important;
  }
}

.form-inline {
  .Select-control {
    min-width: 200px;
  }

  .form-group {
    margin: 0 10px 10px 10px;

    .form-group {
      margin: 0;

      & + .form-group {
        margin-left: 10px;
      }
    }

    label {
      margin-right: 10px;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.page-item--break {
  position: relative;
  display: block;
  padding: 0.25rem 0.5rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--gray-200);
}

.cursor--pointer {
  cursor: pointer;
}

.has-spinner {
  position: relative;
}

.form-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
  text-align: center;
  top: 0;

  .fa {
    position: absolute;
    left: calc(50% - 0.5em);
    top: calc(50% - 0.5em);
  }
}

.drag-handle {
  cursor: move;
}

.btn-transparent {
  color: $gray-dark;
  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  &-danger {
    &:hover,
    &.btn-active {
      color: var(--danger);
    }
  }

  &-primary {
    &:hover,
    &.btn-active {
      color: $brand-primary;
    }
  }
}

//.btn-transparent {
//  $color: $gray-dark;
//  $background: transparent;
//  $border: transparent;
//
//  @include button-variant($color, $background, $border)
//}
.reduced--pageflow-link {
  white-space: nowrap;
  max-width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.nav-tabs {
  li.nav-item {
    a {
      cursor: pointer;
    }
  }
}

.clientListItem {
  i {
    background-position: center center;
  }
}

.ucb {
  &-users-list {
    &--tr {
      .fa-telegram {
        color: #1f96d4;
      }

      .fa-facebook {
        color: #3b5998;
      }
    }

    &--block-status {
      .fa-check-circle {
        color: var(--success);
      }

      .fa-times-circle {
        color: var(--danger);
      }
    }
  }

  &-channel-icon {
    &--telegram {
      color: $telegram;
    }

    &--facebook {
      color: $facebook;
    }

    &--viber {
      color: $viber;
    }

    &--slack {
      color: $viber; //ucb-channel-icon--slack
    }

    &--google {
      color: $google;
    }
  }

  &-livechat-channel-icon {
    max-width: 100px;
    min-width: 42px;
    height: 40px;
    display: inline-block;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
}

.ucb-user-detail {
  .refresh-clientslog {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &--chat-wrapper {
    max-height: 79vh;
    overflow-y: auto;
    position: relative;

    ::-webkit-scrollbar {
      min-width: 12px;
      width: 12px;
      max-width: 12px;
      min-height: 12px;
      height: 12px;
      max-height: 12px;
      background: var(--gray-200);
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 100px;
      border: solid 3px var(--gray-200);
      box-shadow: inset 0px 0px 3px var(--gray-500);
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--gray-300);
      box-shadow: inset 0px 0px 3px var(--gray-600);
    }

    ::-webkit-scrollbar-thumb:active {
      background: var(--gray-400);
      box-shadow: inset 0px 0px 3px var(--gray-600);
    }

    ::-webkit-scrollbar-button {
      display: block;
      height: 0;
    }
  }

  &--chat {
    list-style: none;
    background: none;
    margin: 0;
    padding: 0;

    li {
      padding: 1.5rem 0.5rem 0.5rem 0.5rem;
      overflow: hidden;
      display: flex;
      word-break: break-all;
    }

    .avatar {
      width: 40px;
      height: 40px;
      position: relative;
      display: block;
      z-index: 2;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 1px 7px var(--gray-200);
      margin-right: 10px;
    }

    .avatar img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .day {
      position: relative;
      display: block;
      text-align: center;
      color: var(--gray-300);
      height: 20px;
      text-shadow: 7px 0px 0px var(--gray-200), 6px 0px 0px var(--gray-200), 5px 0px 0px var(--gray-200),
        4px 0px 0px var(--gray-200), 3px 0px 0px var(--gray-200), 2px 0px 0px var(--gray-200),
        1px 0px 0px var(--gray-200), 1px 0px 0px var(--gray-200), 0px 0px 0px var(--gray-200),
        -1px 0px 0px var(--gray-200), -2px 0px 0px var(--gray-200), -3px 0px 0px var(--gray-200),
        -4px 0px 0px var(--gray-200), -5px 0px 0px var(--gray-200), -6px 0px 0px var(--gray-200),
        -7px 0px 0px var(--gray-200);
      box-shadow: inset 20px 0px 0px var(--gray-200), inset -20px 0px 0px var(--gray-200),
        inset 0px -2px 0px var(--gray-200);
      line-height: 38px;
      margin-top: 5px;
      margin-bottom: 20px;
      cursor: default;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .other {
      .avatar {
        .name {
          display: block;
          white-space: nowrap;
          position: absolute;
          top: -23px;
          left: 0;
          color: var(--gray-600);
        }
      }

      .buttons {
        button {
          margin-right: 4px;
          margin-bottom: 4px;
        }
      }

      .msg {
        order: 1;
        border-top-left-radius: 0px;
        box-shadow: -1px 2px 10px var(--gray-200);
      }

      &.switch {
        .msg {
          border: 1px solid $brand-info;
          background-color: lighten($brand-info, 30);
        }
      }
    }

    .self {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .self .msg {
      order: 1;
      border-bottom-right-radius: 0px;
      box-shadow: 1px 2px 10px var(--gray-200);
    }

    .self .avatar {
      margin-right: 0;
      margin-left: 10px;
      order: 2;
      box-shadow: 0 1px 7px var(--gray-200);
    }

    .msg {
      background: white;
      min-width: 50px;
      padding: 10px;
      max-width: 75%;
      border-radius: 2px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
    }

    .msg p {
      font-size: 0.8rem;
      margin: 0 0 0.2rem 0;
      color: var(--gray-600);
    }

    .msg img {
      position: relative;
      display: block;
      max-width: 200px;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: 0px 0px 3px var(--gray-100);
      transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
      cursor: default;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .msg time {
      font-size: 0.7rem;
      color: var(--gray-200);
      margin-top: 3px;
      float: right;
      cursor: default;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .msg time:before {
      content: '\f017';
      color: var(--gray-200);
      font-family: FontAwesome, sans-serif;
      display: inline-block;
      margin-right: 4px;
    }
  }
}

.pagination {
  margin-bottom: 0;

  .page-link {
    cursor: pointer;
  }
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
  height: 0;
  position: absolute;
}

.news-list {
  &--item {
    cursor: pointer;

    &-status {
      &-published {
        font-weight: 700;
        color: var(--success);
      }

      &-danger {
        font-weight: 700;
        color: var(--danger);
      }

      &-created {
        font-weight: 700;
        color: $brand-primary;
      }

      &-canceled {
        font-weight: 700;
        text-decoration: line-through;
        color: $brand-warning;
      }
    }

    &--title {
      max-width: 500px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.ant-select-selection__rendered {
  ul {
    margin: 0;
    padding: 0;
  }
}

.ant-select-search.ant-select-search--inline {
  list-style: none;
}

.color-success {
  color: var(--success);
}

.color-danger {
  color: var(--danger);
}

.color-primary {
  color: $brand-primary;
}

.switch-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5;
}

.createProjectModal {
  .form-control-label {
    line-height: 14px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    &-jaicf {
      display: block;
      margin-top: 10px;
    }
  }

  .externalRepoControlFix {
    label {
      margin-left: 10px;
    }
  }

  .input-group-addon {
    padding: 0;
  }

  .custom-label {
    right: 17px;

    & ~ input {
      padding-right: 30px;
    }
  }

  .justAiKey_input {
    padding-right: 60px;
  }

  .justAiKey_copy {
    right: 138px;
    top: 3px;
  }

  .justAiKey_eye {
    right: 168px;
    top: 3px;
  }

  .help-link {
    text-align: right;
    position: absolute;
    top: 7px;
    right: 24px;

    a {
      vertical-align: middle;
      text-decoration: underline;
    }

    svg {
      margin-left: 5px;
    }
  }
}

.switch-text {
  max-width: 100%;
  border: 1px solid var(--gray-200);
  //line-height: 35px;
  overflow: hidden;
  margin: 0;

  .switch-text-label {
    display: inline-block;
    padding: 7px 20px;
    text-align: center;
    color: var(--primary);
    cursor: pointer;

    &.left {
      border-right: 1px solid var(--gray-200);
    }
  }

  input[type='checkbox'] {
    display: block;
    position: absolute;
    top: -10000px;
    left: -10000px;

    & ~ .left {
      background: var(--gray-100);
      color: var(--gray-900);
    }

    & ~ .right {
      background: none;
      color: var(--primary);
    }

    &:checked {
      & ~ .right {
        background: var(--gray-100);
        color: var(--gray-900);
      }

      & ~ .left {
        background: none;
        color: var(--primary);
      }
    }
  }
}

.skip-readonly[readonly] {
  background-color: var(--white);
}

header.navbar {
  .account {
    .dropdown-menu .dropdown-item {
      padding: 0;

      .nav-link {
        padding: 10px 20px;
      }
    }
  }
}

.dialogs-session-data-chart {
  canvas {
    height: 270px;
    max-height: 270px;
    min-height: 270px;
    width: 100%;
  }
}

.line-through {
  text-decoration: line-through;
}

.modal-super-lg {
  width: 90%;
  max-width: 90%;
}

.react-select-styles {
  border: none;
}

.mb-form-group-0 {
  & .form-group {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.pb-10 {
  padding-bottom: 10px;
}

@media (max-width: 991px) {
  .app-header .navbar-nav.extra-nav {
    right: auto;
    left: 200px;

    select {
      max-width: 70px;
    }

    .account-selector--selector {
      min-width: 70px;
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog.modal-dialog-wide {
    max-width: 800px;
  }
}

.container404 {
  display: flex;
  text-align: center;
  height: calc(100vh - 160px);

  svg {
    width: 287px;
    height: auto;
    margin-bottom: 30px;
  }

  .row {
    margin: auto;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 12px;
    white-space: nowrap;
  }

  p {
    margin-bottom: 32px;
    margin-left: 4.166665%;
  }

  button {
    display: inline-block;
    width: auto;
  }

  #cogwheel_right {
    animation-name: rotate;
    transform-origin: 65.1% 49.55%;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  #cogwheel_left {
    animation-name: rotate;
    transform-origin: 39.2% 49.55%;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.user-settings-fixed-container {
  width: 795px;
  flex-basis: 795px;
  max-width: 795px;
  margin: 0 auto;
}

.user-avatar {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: rgba(249, 249, 249, 1);
  -webkit-background-size: contain;
  background-size: contain;
  margin: 0 auto;
  max-width: 100%;

  img {
    width: 100%;
    height: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.btn-load-avatar {
  display: block;
  margin: 13px auto 0;
  width: 80px;
  position: relative;

  input {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.current-user-email {
  line-height: 35px;
}

.form-section {
  border-bottom: 1px solid var(--gray-300);
  padding-bottom: 30px;
  padding-top: 20px;
  margin-right: -20px;
  margin-left: -20px;
  padding-left: 5px;
  padding-right: 5px;

  &.no-border {
    border-bottom: none;
  }

  .form-section-title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
  }

  label {
    line-height: 35px;
    margin: 0;
  }

  &.form-section-other {
    .form-group {
      margin-bottom: 20px;
    }
  }
}

.p-b-0 {
  padding-bottom: 0;
}

.p-t-0 {
  padding-top: 0;
}

.editor-theme {
  padding: 15px 10px 20px;
  display: inline-block;
  width: 150px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-100);
  }

  &.active {
    background-color: var(--primary-100);
  }
}

.markup-style {
  padding: 15px 10px 20px;
  display: inline-block;
  width: 150px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-100);
  }

  &.active {
    background-color: var(--primary-100);
  }
}

.custom-label.form-user-settings-label {
  right: 15px;
}

.show-all-dialogs {
  text-decoration: underline;
  cursor: pointer;
}

.pagination-all {
  position: relative;
  display: inline-block;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  padding: 0.25rem 0.5rem;
  float: right;
  cursor: pointer;
}

.pagination-all:hover {
  color: var(--primary);
  text-decoration: none;
  background-color: var(--gray-300);
  border-color: var(--gray-200);
}

.anticon-calendar:before,
.anticon-close-circle:before {
  content: none !important;
}

.aside-menu-fixed.aside-menu-hidden.sidebar-mobile-show .app-footer {
  margin-left: 220px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding: 0 10px;

  .react-datepicker__time-list-item {
    height: auto !important;
  }
}

.react-datepicker__close-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  margin-top: -8px;
  right: 7px;
  padding-right: 0;
  display: block;

  &::after {
    right: 0;
    line-height: 10px;
    padding-right: 1px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &:hover::after {
    background-color: rgba(0, 0, 0, 0.45);
  }
}

.react-datepicker-popper-custom {
  position: absolute;
  top: 40px;
  margin: -100vh -100vw;
  padding: 100vh 100vw;
  overflow: hidden;
  z-index: 3;

  .react-datepicker {
    position: absolute;
  }
}

.input-invisible {
  position: absolute;
  top: -10000px;
  left: -10000px;
}

.react-datepicker-popper {
  min-width: 313px;
}

.custom-label-padding {
  padding-right: 0;
}

.custom-userlog-form-width {
  max-width: 850px;
  white-space: nowrap;

  .form-group {
    margin-bottom: 1rem !important;
  }
}

.modal-dialog-telephony {
  .collapsible-content {
    border: none;
    margin-bottom: 1rem;
  }

  .collapsible-content-headline {
    padding: 0;
    background-color: transparent;
  }

  .collapsible-content-body {
    padding: 10px 0;
  }

  .form-check-label {
    display: block;
  }

  .sipHeading_remove-action {
    cursor: pointer;
    display: inline-block;
    height: 35px;
    padding: 7px 0;
  }

  .form-text {
    position: relative;
  }

  .form-group.with-form-text {
    margin-bottom: 0.5rem;
  }
}

.telephony-test-channel-info {
  color: var(--primary-500);
  padding: 8px;
  background-color: var(--primary-100);
  border: 1px solid var(--primary-200);
  line-height: 17px;
}

.telephony-settings-form {
  label.inline-block {
    display: inline-block;
    width: 35%;

    &.health-check-input {
      width: 50%;

      & + input {
        width: 50%;
      }
    }
  }

  label.inline-block + input {
    display: inline-block;
    width: 65%;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .collapsible-content {
    border: none;
    margin-bottom: 1rem;
  }

  .collapsible-content-headline {
    padding: 0;
    background-color: initial;
    font-weight: bold;
    font-size: 16px;
  }

  .justui_spoiler-collapse {
    padding: 10px 0;
  }

  .line-height-fix {
    line-height: 17px;
  }

  .switch {
    margin-top: 5px;
  }

  .sipHeading_remove-action {
    cursor: pointer;
    width: 35px;
    display: inline-block;
    height: 35px;
    line-height: 35px;
    text-align: center;
  }

  .sipHeadingInput {
    display: inline-block;
    width: calc(100% - 35px);
  }

  .flex-center {
    align-items: center;
    display: flex;
  }
}

.popover-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  font-size: 0;
  line-height: 0;
  margin-left: 5px;
  margin-top: -1px;
}

.with-popover {
  padding-right: 0;
}

.react-select.is-invalid > div {
  border-color: var(--danger);
}

select.is-invalid,
input.is-invalid {
  border-color: var(--danger);
}

.siptrunklist .dropdown-toggle::after {
  content: none;
}

.telephony-settings-form {
  .red-dot {
    color: var(--danger);
    padding: 0 6px;
  }
}

.siptrunklist-wrap {
  overflow: visible;
  clear: both;
}

.alert-clear {
  clear: both;
}

.action-buttons {
  &__action {
    .fa {
      font-size: 1.5rem;
    }

    &-label {
      font-size: 80%;
      color: $black;
    }

    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: $gray-lightest;
      border-color: transparent;
    }
  }
}

.siptrunklist-settings .nav-item {
  padding: 0;
}

.siptrunk-delete-modal .close {
  position: absolute;
  right: 20px;
  top: 16px;
}

.td-border-right {
  border-right: 1px solid;
  border-right-color: inherit;
}

.inside-border-top {
  border-top: 1px solid lighten($gray-lighter, 10%);
}

.inside-border-right {
  border-right: 1px solid lighten($gray-lighter, 10%);
}

.inside-border-bottom {
  border-bottom: 1px solid lighten($gray-lighter, 10%);
}

.inside-border-left {
  border-left: 1px solid lighten($gray-lighter, 10%);
}

.sip-trunk-name-pointer {
  cursor: pointer;
  color: var(--primary);
}

@media (min-width: 1600px) {
  .user-settings-fixed-container {
    width: 850px !important;
  }
}

.custom-muted-font {
  font-size: 0.75rem;
}

@media (max-width: 1600px) {
  .connection-list-custom-width {
    width: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
}

.call-task-name-form {
  .btn:disabled {
    background-color: #9c9c9c;
    border-color: #6f6e6e;
    color: var(--white);
  }
}

.call-task-create-form {
  .red-dot {
    color: var(--danger);
    padding: 0 6px;
  }
}

.btn:disabled {
  &.btn-transparent {
    border: none;
    background-color: transparent;
  }
}

.caila-section {
  margin: 0 !important;
  height: 100%;
}

.mb12 {
  margin-bottom: 12px;
}

.g-recaptcha {
  transform: scale(0.9);
}

.sidebar-tariff-data {
  padding: 0 10px;

  .tariff-data-balance {
    background-color: #1a2126;
    padding: 15px 10px;
  }
  .tariffPeriod {
    padding: 10px;
    font-size: 12px;
  }

  #uniqueUsersTooltip {
    margin-left: 10px;
  }
  .unique-users-count {
    color: var(--gray-600);
  }
  .unique-users-count.without-progress {
    margin-bottom: 0;
  }
}
.copy-token-icon {
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
}

.hiddenInput {
  position: absolute;
  top: -10000px;
  left: -10000px;
  visibility: visible;
}

.externalRepository-toggle-custom-mb {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid var(--gray-200) !important;
}

.btn-image {
  display: flex;
  margin-right: 4px;
  align-items: center;
  padding: 7px 6px !important;

  &.w100 {
    width: 100%;
  }

  & .img {
    width: 24px;
    height: 24px;
    justify-content: center;
    margin-right: 4px;

    & i {
      max-width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }

  & span {
    justify-content: center;
  }
}

.old-form-check {
  .form-check {
    padding-left: 0;
    .form-check-label {
      padding-left: $form-check-input-gutter;
    }
  }

  > div.form-control .form-check input[type='radio'] {
    margin-top: 5px;
  }
}

.btn-link:disabled,
.btn-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: $gray-light;
}

@media (max-width: 1350px) {
  .custom-userlist-width {
    width: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}

.datepicker-height-fix {
  &.rdrPositionContainer {
    min-height: 38px;

    &.z11 {
      z-index: 11;
    }

    .rdrInputDateMainContainer {
      height: 38px;
    }
  }
}

.rdrPositionContainer {
  &.disabled {
    pointer-events: none;

    input {
      background-color: var(--gray-200);
      opacity: 1;
      border-color: var(--gray-200);
      color: var(--gray);
    }
  }
}

.react-datepicker-range-fix {
  display: flex;
  .rdrPositionContainer {
    width: 50%;
    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
    * {
      max-width: 100%;
    }
  }
}

.ant-calendar-footer-extra {
  display: flex;
  align-items: center;
  gap: 16px;

  .ant-tag {
    cursor: pointer;
  }
}
