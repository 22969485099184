@import 'settingsMixins';

.Frames {
  width: calc(100vw - 5rem);
  overflow-y: auto;

  &_container {
    max-width: 580px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
  }
  &_description {
    margin-bottom: 8px;
  }
  &_link {
    text-decoration: none;
  }

  &_add_new_container {
    margin-top: 24px;
    width: 100%;
    background-color: white;
    padding: 16px;
  }
}
