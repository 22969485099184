@import './variables';
:root {
  --white-5per: #{$white-5per};
  --black-4per: #{$black-4per};

  --grey-50: #{$grey-50};
  --grey-100: #{$grey-100};
  --grey-200: #{$grey-200};
  --grey-300: #{$grey-300};
  --grey-400: #{$grey-400};
  --grey-500: #{$grey-500};
  --grey-600: #{$grey-600}; // default grey
  --grey-700: #{$grey-700};
  --grey-800: #{$grey-800};
  --grey-900: #{$grey-900};
  --grey-950: #{$grey-950};

  --grey-100--dark: #{$grey-100--dark};
  --grey-200--dark: #{$grey-200--dark};
  --grey-300--dark: #{$grey-300--dark};
  --grey-400--dark: #{$grey-400--dark};
  --grey-500--dark: #{$grey-500--dark}; //default grey
  --grey-600--dark: #{$grey-600--dark};
  --grey-700--dark: #{$grey-700--dark};
  --grey-800--dark: #{$grey-800--dark};
  --grey-900--dark: #{$grey-900--dark};
  --grey-950--dark: #{$grey-950--dark};

  --gray-50: #{$grey-50};
  --gray-100: #{$grey-100};
  --gray-200: #{$grey-200};
  --gray-300: #{$grey-300};
  --gray-400: #{$grey-400};
  --gray-500: #{$grey-500};
  --gray-600: #{$grey-600}; // default grey
  --gray-700: #{$grey-700};
  --gray-800: #{$grey-800};
  --gray-900: #{$grey-900};
  --gray-950: #{$grey-950};

  --gray-100--dark: #{$grey-100--dark};
  --gray-200--dark: #{$grey-200--dark};
  --gray-300--dark: #{$grey-300--dark};
  --gray-400--dark: #{$grey-400--dark};
  --gray-500--dark: #{$grey-500--dark}; //default grey
  --gray-600--dark: #{$grey-600--dark};
  --gray-700--dark: #{$grey-700--dark};
  --gray-800--dark: #{$grey-800--dark};
  --gray-900--dark: #{$grey-900--dark};
  --gray-950--dark: #{$grey-950--dark};

  --green-100: #{$green-100};
  --green-200: #{$green-200};
  --green-300: #{$green-300};
  --green-400: #{$green-400};
  --green-500: #{$green-500};
  --green-600: #{$green-600}; // default green
  --green-700: #{$green-700};
  --green-800: #{$green-800};
  --green-900: #{$green-900};
  --green-950: #{$green-950};

  --green-100--dark: #{$green-100--dark};
  --green-200--dark: #{$green-200--dark};
  --green-300--dark: #{$green-300--dark};
  --green-400--dark: #{$green-400--dark};
  --green-500--dark: #{$green-500--dark}; //default green
  --green-600--dark: #{$green-600--dark};
  --green-700--dark: #{$green-700--dark};
  --green-800--dark: #{$green-800--dark};
  --green-900--dark: #{$green-900--dark};
  --green-950--dark: #{$green-950--dark};

  --blue-50: #{$blue-50};
  --blue-100: #{$blue-100};
  --blue-200: #{$blue-200};
  --blue-300: #{$blue-300};
  --blue-400: #{$blue-400};
  --blue-500: #{$blue-500};
  --blue-600: #{$blue-600}; // default blue
  --blue-700: #{$blue-700};
  --blue-800: #{$blue-800};
  --blue-900: #{$blue-900};
  --blue-950: #{$blue-950};

  --blue-100--dark: #{$blue-100--dark};
  --blue-200--dark: #{$blue-200--dark};
  --blue-300--dark: #{$blue-300--dark};
  --blue-400--dark: #{$blue-400--dark};
  --blue-500--dark: #{$blue-500--dark}; //default blue
  --blue-600--dark: #{$blue-600--dark};
  --blue-700--dark: #{$blue-700--dark};
  --blue-800--dark: #{$blue-800--dark};
  --blue-900--dark: #{$blue-900--dark};
  --blue-950--dark: #{$blue-950--dark};

  --aimylogic-100: #{$aimylogic-100};
  --aimylogic-200: #{$aimylogic-200};
  --aimylogic-300: #{$aimylogic-300};
  --aimylogic-400: #{$aimylogic-400};
  --aimylogic-500: #{$aimylogic-500};
  --aimylogic-600: #{$aimylogic-600}; // default aimylogic
  --aimylogic-700: #{$aimylogic-700};
  --aimylogic-800: #{$aimylogic-800};
  --aimylogic-900: #{$aimylogic-900};
  --aimylogic-950: #{$aimylogic-950};

  --aimylogic-100--dark: #{$aimylogic-100--dark};
  --aimylogic-200--dark: #{$aimylogic-200--dark};
  --aimylogic-300--dark: #{$aimylogic-300--dark};
  --aimylogic-400--dark: #{$aimylogic-400--dark};
  --aimylogic-500--dark: #{$aimylogic-500--dark}; //default aimylogic
  --aimylogic-600--dark: #{$aimylogic-600--dark};
  --aimylogic-700--dark: #{$aimylogic-700--dark};
  --aimylogic-800--dark: #{$aimylogic-800--dark};
  --aimylogic-900--dark: #{$aimylogic-900--dark};
  --aimylogic-950--dark: #{$aimylogic-950--dark};

  --indigo-100: #{$indigo-100};
  --indigo-200: #{$indigo-200};
  --indigo-300: #{$indigo-300};
  --indigo-400: #{$indigo-400};
  --indigo-500: #{$indigo-500};
  --indigo-600: #{$indigo-600}; // default indigo
  --indigo-700: #{$indigo-700};
  --indigo-800: #{$indigo-800};
  --indigo-900: #{$indigo-900};
  --indigo-950: #{$indigo-950};

  --indigo-100--dark: #{$indigo-100--dark};
  --indigo-200--dark: #{$indigo-200--dark};
  --indigo-300--dark: #{$indigo-300--dark};
  --indigo-400--dark: #{$indigo-400--dark};
  --indigo-500--dark: #{$indigo-500--dark}; //default indigo
  --indigo-600--dark: #{$indigo-600--dark};
  --indigo-700--dark: #{$indigo-700--dark};
  --indigo-800--dark: #{$indigo-800--dark};
  --indigo-900--dark: #{$indigo-900--dark};
  --indigo-950--dark: #{$indigo-950--dark};

  --jaicp-100: #{$jaicp-100};
  --jaicp-200: #{$jaicp-200};
  --jaicp-300: #{$jaicp-300};
  --jaicp-400: #{$jaicp-400};
  --jaicp-500: #{$jaicp-500};
  --jaicp-600: #{$jaicp-600}; // default jaicp
  --jaicp-700: #{$jaicp-700};
  --jaicp-800: #{$jaicp-800};
  --jaicp-900: #{$jaicp-900};
  --jaicp-950: #{$jaicp-950};

  --jaicp-100--dark: #{$jaicp-100--dark};
  --jaicp-200--dark: #{$jaicp-200--dark};
  --jaicp-300--dark: #{$jaicp-300--dark};
  --jaicp-400--dark: #{$jaicp-400--dark};
  --jaicp-500--dark: #{$jaicp-500--dark}; //default jaicp
  --jaicp-600--dark: #{$jaicp-600--dark};
  --jaicp-700--dark: #{$jaicp-700--dark};
  --jaicp-800--dark: #{$jaicp-800--dark};
  --jaicp-900--dark: #{$jaicp-900--dark};
  --jaicp-950--dark: #{$jaicp-950--dark};

  --red-100: #{$red-100};
  --red-200: #{$red-200};
  --red-300: #{$red-300};
  --red-400: #{$red-400};
  --red-500: #{$red-500};
  --red-600: #{$red-600}; // default red
  --red-700: #{$red-700};
  --red-800: #{$red-800};
  --red-900: #{$red-900};
  --red-950: #{$red-950};

  --red-100--dark: #{$red-100--dark};
  --red-200--dark: #{$red-200--dark};
  --red-300--dark: #{$red-300--dark};
  --red-400--dark: #{$red-400--dark};
  --red-500--dark: #{$red-500--dark}; //default red
  --red-600--dark: #{$red-600--dark};
  --red-700--dark: #{$red-700--dark};
  --red-800--dark: #{$red-800--dark};
  --red-900--dark: #{$red-900--dark};
  --red-950--dark: #{$red-950--dark};

  --yellow-100: #{$yellow-100};
  --yellow-200: #{$yellow-200};
  --yellow-300: #{$yellow-300}; // default yellow
  --yellow-400: #{$yellow-400};
  --yellow-500: #{$yellow-500};
  --yellow-600: #{$yellow-600};
  --yellow-700: #{$yellow-700};
  --yellow-800: #{$yellow-800};
  --yellow-900: #{$yellow-900};
  --yellow-950: #{$yellow-950};

  --yellow-100--dark: #{$yellow-100--dark};
  --yellow-200--dark: #{$yellow-200--dark};
  --yellow-300--dark: #{$yellow-300--dark};
  --yellow-400--dark: #{$yellow-400--dark};
  --yellow-500--dark: #{$yellow-500--dark};
  --yellow-600--dark: #{$yellow-600--dark};
  --yellow-700--dark: #{$yellow-700--dark};
  --yellow-800--dark: #{$yellow-800--dark}; //default yellow
  --yellow-900--dark: #{$yellow-900--dark};
  --yellow-950--dark: #{$yellow-950--dark};

  --orange-100: #{$orange-100};
  --orange-200: #{$orange-200};
  --orange-300: #{$orange-300};
  --orange-400: #{$orange-400};
  --orange-500: #{$orange-500};
  --orange-600: #{$orange-600}; // default aimyvoice
  --orange-700: #{$orange-700};
  --orange-800: #{$orange-800};
  --orange-900: #{$orange-900};
  --orange-950: #{$orange-950};

  --orange-100--dark: #{$orange-100--dark};
  --orange-200--dark: #{$orange-200--dark};
  --orange-300--dark: #{$orange-300--dark};
  --orange-400--dark: #{$orange-400--dark};
  --orange-500--dark: #{$orange-500--dark}; //default aimyvoice
  --orange-600--dark: #{$orange-600--dark};
  --orange-700--dark: #{$orange-700--dark};
  --orange-800--dark: #{$orange-800--dark};
  --orange-900--dark: #{$orange-900--dark};
  --orange-950--dark: #{$orange-950--dark};

  --primary-100: #{$primary-100};
  --primary-200: #{$primary-200};
  --primary-300: #{$primary-300};
  --primary-400: #{$primary-400};
  --primary-500: #{$primary-500};
  --primary-600: #{$primary-600};
  --primary-700: #{$primary-700};
  --primary-800: #{$primary-800};
  --primary-900: #{$primary-900};
  --primary-950: #{$primary-950};

  .justui__theme-dark {
    --grey-100: var(--grey-100--dark);
    --grey-200: var(--grey-200--dark);
    --grey-300: var(--grey-300--dark);
    --grey-400: var(--grey-400--dark);
    --grey-500: var(--grey-500--dark);
    --grey-600: var(--grey-600--dark);
    --grey-700: var(--grey-700--dark);
    --grey-800: var(--grey-800--dark);
    --grey-900: var(--grey-900--dark);
    --grey-950: var(--grey-950--dark);

    --gray-100: var(--grey-100--dark);
    --gray-200: var(--grey-200--dark);
    --gray-300: var(--grey-300--dark);
    --gray-400: var(--grey-400--dark);
    --gray-500: var(--grey-500--dark);
    --gray-600: var(--grey-600--dark);
    --gray-700: var(--grey-700--dark);
    --gray-800: var(--grey-800--dark);
    --gray-900: var(--grey-900--dark);
    --gray-950: var(--grey-950--dark);

    --green-100: var(--green-100--dark);
    --green-200: var(--green-200--dark);
    --green-300: var(--green-300--dark);
    --green-400: var(--green-400--dark);
    --green-500: var(--green-500--dark);
    --green-600: var(--green-600--dark);
    --green-700: var(--green-700--dark);
    --green-800: var(--green-800--dark);
    --green-900: var(--green-900--dark);
    --green-950: var(--green-950--dark);

    --blue-100: var(--blue-100--dark);
    --blue-200: var(--blue-200--dark);
    --blue-300: var(--blue-300--dark);
    --blue-400: var(--blue-400--dark);
    --blue-500: var(--blue-500--dark);
    --blue-600: var(--blue-600--dark);
    --blue-700: var(--blue-700--dark);
    --blue-800: var(--blue-800--dark);
    --blue-900: var(--blue-900--dark);
    --blue-950: var(--blue-950--dark);

    --aimylogic-100: var(--aimylogic-100--dark);
    --aimylogic-200: var(--aimylogic-200--dark);
    --aimylogic-300: var(--aimylogic-300--dark);
    --aimylogic-400: var(--aimylogic-400--dark);
    --aimylogic-500: var(--aimylogic-500--dark);
    --aimylogic-600: var(--aimylogic-600--dark);
    --aimylogic-700: var(--aimylogic-700--dark);
    --aimylogic-800: var(--aimylogic-800--dark);
    --aimylogic-900: var(--aimylogic-900--dark);
    --aimylogic-950: var(--aimylogic-950--dark);

    --jaicp-100: var(--jaicp-100--dark);
    --jaicp-200: var(--jaicp-200--dark);
    --jaicp-300: var(--jaicp-300--dark);
    --jaicp-400: var(--jaicp-400--dark);
    --jaicp-500: var(--jaicp-500--dark);
    --jaicp-600: var(--jaicp-600--dark);
    --jaicp-700: var(--jaicp-700--dark);
    --jaicp-800: var(--jaicp-800--dark);
    --jaicp-900: var(--jaicp-900--dark);
    --jaicp-950: var(--jaicp-950--dark);

    --red-100: var(--red-100--dark);
    --red-200: var(--red-200--dark);
    --red-300: var(--red-300--dark);
    --red-400: var(--red-400--dark);
    --red-500: var(--red-500--dark);
    --red-600: var(--red-600--dark);
    --red-700: var(--red-700--dark);
    --red-800: var(--red-800--dark);
    --red-900: var(--red-900--dark);
    --red-950: var(--red-950--dark);

    --yellow-100: var(--yellow-100--dark);
    --yellow-200: var(--yellow-200--dark);
    --yellow-300: var(--yellow-300--dark);
    --yellow-400: var(--yellow-400--dark);
    --yellow-500: var(--yellow-500--dark);
    --yellow-600: var(--yellow-600--dark);
    --yellow-700: var(--yellow-700--dark);
    --yellow-800: var(--yellow-800--dark);
    --yellow-900: var(--yellow-900--dark);
    --yellow-950: var(--yellow-950--dark);
  }
}
