@import "~@just-ai/just-ui/scss/basic/custom/functions";
@import "~@just-ai/just-ui/scss/basic/custom/variables";

.ReactSelect {
  //z-index: 10;

  &.separatedOptions{
    .ReactSelectPrefix__option{
      border-bottom: 1px solid $black-light;
    }
  }

  .ReactSelectPrefix__value-container{
    padding-left: 12px;
    color: $grey-600;

    &--is-multi.ReactSelectPrefix__value-container--has-value{
      padding-left: 3px !important;
    }
  }

  .ReactSelectPrefix__placeholder{
      color: var(--grey-500);
  }

  .ReactSelectPrefix__single-value{
    color: #000000;
  }

  .ReactSelectPrefix__input {
    color: var(--gray-950);
  }

  &.justui-select-error {
    .ReactSelectPrefix__control{
      border-color: $danger;
      &:hover {
        border-color: $danger;
      }
      &.ReactSelectPrefix__control--is-focused{
        border-color: $danger;
        box-shadow: 0 0 0 0.2rem rgba($danger, .3);
      }
    }
  }

  .ReactSelectPrefix__control{
    border-color: $greyblue;

    &:hover{
      border-color: $grey-600;
    }
    &.ReactSelectPrefix__control--is-focused{
      border-color: var(--primary);
      box-shadow: 0 0 3px 0px var(--primary-300);
    }
    &.ReactSelectPrefix__control--is-disabled{
      border-color: $greyblue;
      background: $grey-100;

      .ReactSelectPrefix__single-value{
        color: $grey-600;
      }
    }
  }

  .ReactSelectPrefix__option {
    padding: 0;
    cursor: pointer;

    span{
      display: block;
      padding: 8px 12px;

      &.multiple-option{
        display: flex;
        align-items: center;
      }
    }

    &:not(.ReactSelectPrefix__option--is-disabled):not(.ReactSelectPrefix__option--is-selected):hover {
      background: var(--primary);
      color: var(--white);
    }

    &:focus,
    &.ReactSelectPrefix__option--is-focused {
      background: var(--primary);
      color: var(--white);
    }

    &.ReactSelectPrefix__option--is-selected {
      color: var(--white);
      background: var(--primary);
    }

    &.ReactSelectPrefix__option--is-disabled{
      color: var(--gray-600);
      cursor: not-allowed;
    }
  }

  .ReactSelectPrefix__menu-list {
    padding-top: 8px;
    padding-bottom: 8px;

    &--is-multi{
      .ReactSelectPrefix__option{
        .custom-control-label::before{
          top: 0.2rem;
        }
        .custom-control-label::after{
          top: 0.2rem;
        }
        &.ReactSelectPrefix__option--is-selected{
          background: inherit;
          color: inherit;
        }
      }
    }
  }

  .ReactSelectPrefix__indicator-separator{
    display: none;
  }

  .ReactSelectPrefix__indicators {
    justify-content: center;
    .ReactSelectPrefix__indicator {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        font-size: .8rem;
      }

      &.clear {
        svg {
          font-size: 1rem;
        }
      }

    }
  }

  &.arrowLeft{
    .ReactSelectPrefix__control{
      //flex-direction: row-reverse;
    }
    .ReactSelectPrefix__value-container{
      padding-left: 36px;
    }
    .ReactSelectPrefix__indicator.arrow-indicator{
      position: absolute;
      left: 0;
    }
  }

  .ReactSelectPrefix__menu {
    z-index: 12;
    border-radius: 3px;
    box-shadow: 0px 0px 1px rgba(17, 60, 92, 0.06), 0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
  }

  .ReactSelectPrefix__group{
    padding-top: 0;
    padding-bottom: 8px;

    /*&:last-of-type{
      padding-bottom: 0;
    }*/
  }

  .ReactSelectPrefix__group-heading{
    padding: 8px 12px;
    color: $grey-600;
    font-size: 14px;
    line-height: 21px;
    text-transform: none;
    font-weight: normal;
    border-bottom: 1px solid $black-light;
    margin: 0;
  }

  .ReactSelectPrefix__multi-value{
    border: none;
    background: $black-light;

    &__label{
      padding: 1px 6px;
      font-size: 14px;
      line-height: 24px;
    }

    &__remove{
      border-left: 1px solid $white;
      border-radius: 0;
      cursor: pointer;
      width: 26px;
      text-align: center;
      display: flex;
      justify-content: center;
      padding: 0;
      svg{
        width: 15px !important;
        font-size: .91rem !important;
      }
      &:hover{
        background-color: $greyblue;
        svg{
          color: #000;
        }
      }
    }
  }

  &.dark {
    .ReactSelectPrefix__value-container{
      color: white !important;

      &:hover{
        border-color: white;
      }
    }

    .ReactSelectPrefix__multi-value {
      color: white;
      background-color: $grey-600;

      &__remove{
        border-left: 1px solid #273238;
        svg{
          color: $black-light;
        }
        &:hover{
          background-color: var(--primary);
          svg{
            color: $white;
          }
        }
      }
    }

    .justui_icon.svg-inline--fa:not(.btn-color).fa-color-secondary {
      color: var(--greyblue);
    }

    .ReactSelectPrefix__multi-value__label {
      color: white;
      background-color: $grey-600;
    }

    .ReactSelectPrefix__placeholder{
      color: var(--grey);
    }

    .ReactSelectPrefix__single-value{
      color: white;
      &--is-disabled {
        color: #999;
      }
    }
    .ReactSelectPrefix__control{
      background-color: $greyblue-dark;
      border-color: $grey-600;

      &:hover {
        border-color: $greyblue;
      }

      &.ReactSelectPrefix__control--is-focused{
        border-color: var(--primary-200);
        //box-shadow: 0 0 0 0.2rem rgba(46,169,214, 0.3);
      }
      &.ReactSelectPrefix__control--is-disabled{
        background: $grey-700;
        .ReactSelectPrefix__single-value{
            color: #999;
        }
      }
    }

    .ReactSelectPrefix__menu-list {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #1F282D;
      color: white;

      &--is-multi{
        .ReactSelectPrefix__option{
          .custom-control-label::before{
            border-color: $greyblue;
            background: transparent;
          }
          .custom-control-label::after{
            top: 0.2rem;
            //background: transparent;
          }
          &.ReactSelectPrefix__option--is-selected{
            background: inherit;
            color: inherit;

            .custom-control-label::before {
              background: var(--primary);
              border-color: var(--primary);
            }
          }
          &.ReactSelectPrefix__option--is-disabled{
            background: inherit;
            color: inherit;

            .custom-control-label::before {
              background: transparent;
              border-color: $grey-600;
            }
          }
        }
      }
    }

    .ReactSelectPrefix__option {
      padding: 0;

      span{
        display: block;
        padding: 8px 12px;

        &.multiple-option{
          display: flex;
          align-items: center;
        }
      }

      &:hover {
        background: #273238!important;
      }

      &:focus,
      &.ReactSelectPrefix__option--is-focused {
        background: #273238;
        color: white;
      }

      &.ReactSelectPrefix__option--is-selected {
        color: $white;
        background: var(--primary);
      }
    }

    .ReactSelectPrefix__indicator > svg {
      color: white;
    }

    &.justui-select-error {
      .ReactSelectPrefix__control{
        border-color: $danger-light-20;
        &:hover {
          border-color: $danger-light-20;
        }
        &.ReactSelectPrefix__control--is-focused{
          border-color: $danger-light-20;
          //box-shadow: 0 0 0 0.2rem rgba($danger, .3);
        }
      }
    }
  }

  &.xs {
    .ReactSelectPrefix__value-container{
      font-size: 12px;
      line-height: 18px;
      padding-left: 6px;

      &--is-multi.ReactSelectPrefix__value-container--has-value{
        padding-left: 2px !important;
      }
    }
    .ReactSelectPrefix__control {
      min-height: 24px;
    }
    &.arrowLeft {
      .ReactSelectPrefix__value-container {
        padding-left: 22px;
      }
    }
    .ReactSelectPrefix__indicators {
      .ReactSelectPrefix__indicator{
        height: 22px;
        width: 22px;

        svg {
          font-size: .64rem;
        }

        &.clear {
          svg {
            font-size: .5625rem;
          }
        }
      }
    }
    .ReactSelectPrefix__menu-list{
      padding-top: 4px;
      padding-bottom: 4px;

      .ReactSelectPrefix__option {
        span {
          padding: 3px 8px;
          font-size: 12px;
          line-height: 18px;
        }
      }

      &--is-multi{
        .ReactSelectPrefix__option{
          .custom-control-label::before{
            top: 0.1rem;
          }
          .custom-control-label::after{
            top: 0.1rem;
          }
        }
      }
    }

    .ReactSelectPrefix__multi-value{
      &__label{
        padding: 0px 4px;
        font-size: 12px;
        line-height: 16px;
      }

      &__remove{
        width: 16px;
        svg{
          font-size: .546rem !important;
        }
      }
    }
  }

  &.sm{
    .ReactSelectPrefix__value-container{
      font-size: 12px;
      line-height: 20px;
      padding-left: 6px;

      &--is-multi.ReactSelectPrefix__value-container--has-value{
        padding-left: 2px !important;
      }
    }
    .ReactSelectPrefix__control{
      min-height: 30px;
    }
    &.arrowLeft {
      .ReactSelectPrefix__value-container {
        padding-left: 28px;
      }
    }
    .ReactSelectPrefix__indicators {
      .ReactSelectPrefix__indicator{
        height: 28px;
        width: 28px;

        svg {
          font-size: .64rem;
        }

        &.clear {
          svg {
            font-size: .75rem;
          }
        }
      }
    }

    .ReactSelectPrefix__multi-value{
      &__label{
        padding: 0px 4px;
        font-size: 12px;
        line-height: 20px;
      }

      &__remove{
        width: 20px;
          svg{
            font-size: 0.7275rem !important;
          }
      }
    }
  }

  &.lg{
    .ReactSelectPrefix__value-container{
      font-size: 16px;
      line-height: 28px;
      padding-left: 16px;

      &--is-multi.ReactSelectPrefix__value-container--has-value{
        padding-left: 5px !important;
      }
    }
    .ReactSelectPrefix__control{
      min-height: 44px;
    }
    &.arrowLeft {
      .ReactSelectPrefix__value-container {
        padding-left: 44px;
      }
    }
    .ReactSelectPrefix__indicators {
      .ReactSelectPrefix__indicator{
        height: 44px;
        width: 44px;

        svg {
          font-size: 1rem;
        }

        &.clear {
          svg {
            font-size: 1.25rem;
          }
        }
      }
    }

    .ReactSelectPrefix__multi-value{
      &__label{
        padding: 1px 7px;
        font-size: 16px;
        line-height: 28px;
      }

      &__remove{
        width: 30px;
        svg{
          //font-size: 0.75rem;
        }
      }
    }
  }
}
