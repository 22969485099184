.rdrInputDateMainContainer {
  width: 100%;
  height: 54px;
  padding: 0px;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .rdrInputDateContainer {
    input {
      padding-left: 34px;
    }
  }

  .rdrInputCalendarIcon.justui_icon.svg-inline--fa {
    position: absolute;
    height: 100%;
    width: 1rem;
    top: 0;
    left: 13px;
  }

  .rdrInputDateIconContainer {
    position: absolute;
    top: 0;
    right: 0;

    background: transparent;
    height: 100%;
    border-radius: 50%;
    border: none;
    width: 36px;

    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;

    & > * {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      transition: 300ms;
    }

    & > *:first-child {
      opacity: 0;
    }

    & > *:last-child {
      opacity: 1;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
