.Sidebar {
  width: 3.5rem;
  height: 100%;
  padding: 1rem 0.5rem;
  border-right: 1px solid var(--gray-200);
  background-color: var(--white);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  .Iframes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;

    hr {
      width: 100%;
      margin-bottom: 0;
    }

    &_item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      color: var(--indigo-600);
      font-size: 12px;

      &.active {
        background-color: var(--indigo-200);
        color: var(--indigo-800);
      }
      &.disabled {
        cursor: not-allowed;
        color: var(--gray-500);
      }
      &:hover {
        background-color: var(--indigo-100);
        cursor: pointer;
      }

      .spinner-border {
        position: absolute;
      }
    }
  }
}
