@mixin main {
  height: 32px !important;
  font-size: 12px !important;
}

.Wrapper {
  position: relative;

  input {
    @include main;
    width: 65px !important;
    padding: 0.5rem 36px 0.5rem 12px !important;
    text-align: center;
  }
}

.Description {
  @include main;
  top: 0;
  right: 12px;
  line-height: 32px;
  position: absolute;
  color: var(--gray-600);
}
