.DialogMessage_container {
  margin-top: 12px;
  padding: 0 16px;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  border: none;

  .DialogMessage {
    max-width: calc(100% - 30px - 11px);
    padding: 8px 12px;
    background-color: var(--white);
    display: grid;
    grid-template-columns: 12px min-content;
    grid-template-rows: min-content min-content;
    grid-template: 'name name' 'text date';
    gap: 4px;
    border-radius: 0.5rem;
    animation: messageAppearance;
    animation-duration: 300ms;
    transform: translateY(0);
    opacity: 1;

    &_comment {
      background-color: var(--yellow-100);
    }

    &:hover {
      .PrompterIcon {
        display: inline;
      }
    }

    &_mobile {
      width: 100%;
      &__bot {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    &_name {
      grid-area: name;
      color: var(--blue-500);
      font-size: 12px;
      line-height: 18px;
      height: 18px;
      text-align: right;
      display: flex;

      svg:hover {
        cursor: pointer;
      }
    }
    &_image {
      height: 150px;
      max-width: 100%;
      overflow: hidden;
      border-radius: 4px;
      margin-top: 0.5rem;
      &:hover {
        cursor: pointer;
      }
      img,
      video {
        height: 100%;
      }
    }
    &_audio {
      height: 38px;
      max-width: 100%;
      overflow: hidden;
      border-radius: 2px;
      margin-top: 0.5rem;
      audio {
        height: 100%;
      }
    }
    &_text {
      grid-area: text;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      color: var(--gray-950);
      font-size: 14px;
      line-height: 21px;
      max-width: 300px;

      &.ClientMessage {
        align-items: flex-start;
      }

      & > div,
      & > a {
        white-space: pre-wrap;
        word-break: break-word;
        max-height: 60rem;
        overflow: auto;
      }
    }

    &_link {
      margin-top: 0.5rem;
    }
    &_date {
      grid-area: date;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      span {
        color: var(--gray-400);
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .PrompterIcon {
    display: none;
  }

  .DialogMessage_avatar {
    align-self: flex-end;
    height: 30px;
    width: 30px;
    margin-left: 12px;
    animation: avatarAppear 300ms 200ms cubic-bezier(0.5, 1.41, 0.29, -0.34);
    animation-fill-mode: forwards;
    transform: scale(0);

    &.bot {
      background-image: url(../../../img/avatar_bot.svg);
    }
    &.operator {
      background-image: url(../../../img/avatar_operator.svg);
    }
  }
  &_client {
    justify-content: flex-start;
    .DialogMessage {
      max-width: 100%;
      &_name {
        text-align: left;
      }
    }
  }
}

.DialogMessage_lastDate {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 4px;
  color: var(--gray-400);

  animation: messageAppearance;
  animation-duration: 200ms;
}

@keyframes messageAppearance {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  20% {
    transform: translateY(-4px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes avatarAppear {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes messageAppearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
