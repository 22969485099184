.NoAccessPage {
  &_container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.5rem;

    &_className {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.02em;
    }
  }
  &_centerPart {
    height: 261px;
    max-height: 80vh;
    width: 467px;
    max-width: calc(100vw - 1rem);

    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    & > * {
      width: calc(100vw - 3rem);
      max-width: 387px;
      text-align: center;
    }
  }

  &_userInfo {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: var(--gray-100);
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > * {
      font-size: 14px;
      color: var(--gray-800);
    }
    & > *:last-child,
    & > *:first-child {
      font-weight: 700;
    }
    &__organization {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.02em;
    }
  }
}
