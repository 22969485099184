.Settings {
  padding: 1.5rem;
  display: grid;
  height: 100%;
  max-height: calc(100vh - 3rem);
  grid-template-columns: 1fr;
  grid-template-rows: 1.5rem calc(100% - 1.5rem);
  position: relative;
  gap: 1.5rem;
  width: 100%;
  overflow: hidden;
  @media (max-width: 620px) {
    padding: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    & > h2 {
      margin-left: 1rem;
    }
  }
  .Settings_tabs {
    margin-top: 0.5rem;

    position: absolute;
    grid-row: 2/3;
    grid-column: 1/2;

    display: grid;
    grid-template-rows: 4rem calc(100% - 4rem);
    height: 100%;
    padding-bottom: 1rem;
    @media (max-width: 620px) {
      max-width: 100vw;
      height: calc(100% - 2rem);
      padding: 0;
      & > * {
        padding-left: 1rem;
        padding-right: 1rem;
        &:not(:first-child) {
          width: 100vw;
        }
      }
    }
    .Settings_tabs_navigator {
      width: 800px;
      max-width: 100vw;
      @media (max-width: 620px) {
        padding: 0 1rem;
      }
    }
  }
  h1 {
    color: var(--gray-950);
  }

  .Groups_groupList,
  .Operators_operatorList {
    min-width: 800px;

    &_wrapper {
      display: flex;
      margin-top: 24px;
      overflow: scroll;
      height: calc(100% - 8rem);
      width: calc(100vw - 5rem);
    }

    @media (max-width: 620px) {
      min-width: auto;

      &_wrapper {
        flex-direction: column-reverse;
      }
    }
  }

  .Groups_operatorList_wrapper {
    justify-content: space-between;
  }
}
.CreateOperatorModal_container {
  .just-select-selected-item {
    color: var(--gray-800);
  }
  p:first-child {
    margin-bottom: 8px;
  }
}

.ErrorMessage {
  margin-bottom: 0 !important;
  color: var(--red-600);
}

.DangerBorder {
  border: 1px solid var(--red-600) !important;
  border-radius: 4px;
}

.beta {
  margin-left: 4px;
  color: white;
  background-color: var(--red-600);
  border-radius: 2px;
  padding: 2px 4px;
}
