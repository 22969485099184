@use "sass:math";

.Collapse-custom {
  display: flex;
  flex-direction: row;
  padding: 14px 32px;
  align-items: center;
  border-bottom: 1px solid var(--gray-200, #e0e1e3);
  background-color: var(--white, #fff);

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &.opened {
    border-bottom-color: transparent;
    background-color: var(--gray-100, #f4f5f5);
  }

  &-heading {
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    font-weight: 500;
    font-size: #{math.div(14, 16)}rem;
    line-height: 20px;
    color: var(--gray-950, #0d1822);

    min-height: 20px;

    &-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: center;
      transition: all 0.3s ease-in-out;
      transform: rotate(-180deg);

      &.opened-icon {
        transform: rotate(0deg);
      }
    }
  }
}
