.rdrFastChoicePeriodsContainer {
  height: 46px;
  padding-top: 8px;

  grid-row: 3/-1;
  grid-column: 1/-1;
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-top: 1px solid #E0E1E3;
}
