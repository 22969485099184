header {
  .Header_container {
    display: flex;
    justify-content: space-between;
    height: 3.5rem;
    width: 100vw;
    background-color: var(--white);
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--gray-200);
    img {
      height: 24px;
    }
    @media (max-width: 620px) {
      .Header_burger,
      .Header_menu {
        display: flex !important;
      }
      .Header_organization,
      .Header_onlineStatus,
      .Header_help,
      .Header_name,
      .Header_cc {
        display: none !important;
      }
    }

    .Header_cc {
      .dropdown-menu {
        z-index: 10000;
      }
    }
  }
  .Header_burger,
  .Header_menu {
    display: none;
  }
}

.Header {
  &_container {
    width: 100vw;
    & > * {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  &_organization {
    margin-left: 24px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
  }

  &_help {
    margin-right: 16px;
  }

  &_onlineStatus {
    margin-right: 16px;
    .custom-switch .custom-control-label {
      white-space: nowrap;
    }
    label {
      width: 60px;
    }
  }

  &_name {
    color: var(--indigo-600);
    margin-right: 16px;
    &_popover {
      .popover {
        width: 264px;
        .dropdown-item {
          display: grid;
          grid-template-columns: 30px auto;
          grid-template-rows: 20px auto;
          grid-column-gap: 8px;
        }
      }
    }
  }
  &_cc {
    &_popover {
      .dropdown-item {
        display: grid !important;
        grid-template-columns: 40px auto !important;
        img {
          height: 24px;
        }
      }
    }
  }
  &_burger {
    button {
      height: 40px;
      width: 40px;
    }
  }
}

.Header_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem;
  height: 0;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 3.5rem;
  left: 0;

  transition: 300ms ease-in-out;

  &.shown {
    height: calc(100vh - 3.5rem);
    display: flex !important;
    background-color: var(--white);
    z-index: 10000;
  }
  & > div:first-child,
  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1rem;
  }
  & > div:last-child {
    justify-content: flex-end;
    gap: 1rem;
  }
  &_divider {
    width: 100%;
    height: 1px;
    background-color: var(--gray-200);
  }
  &_name {
    display: grid;
    gap: 8px;
    grid-template-columns: 1rem auto;
    grid-auto-rows: min-content;
    padding: 0 12px;
    color: var(--indigo-600);
    &_email {
      grid-column: 2/3;
      color: var(--gray-500);
    }
  }
  &_help {
    display: grid;
    gap: 8px;
    grid-template-columns: 1rem auto;
    grid-auto-rows: min-content;
    padding: 0 12px;
    color: var(--indigo-600);
    text-decoration: none;
  }
  &_logout {
    button {
      padding: 0 12px !important;
      svg {
        margin-right: 8px;
      }
    }
  }
}

.LocaleSwitcher {
  &.show > button,
  button:hover {
    background-color: var(--gray-200);
  }
}

.OfflineModal {
  input {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
