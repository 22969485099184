@mixin days() {
  .rdrDay {
    margin: 0 !important;
    height: 2rem !important;
    width: 2rem;
    padding: 4px 0 4px 0 !important;
    box-sizing: inherit;
    position: relative;
    font: inherit;
    cursor: pointer;

    * {
      font-size: 14px;
      color: var(--rdr-day);
    }

    .rdrDayNumber {
      height: 1.5rem;
      width: 2rem;
      line-height: 1.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      color: var(--rdr-black);
      transition: 300ms;
      background-color: var(--rdr-white);

      & > span {
        box-sizing: border-box;
        padding: 0 2px;
        height: 24px;
        border-radius: 2px;
        line-height: 24px;
        width: 24px;
      }
    }


    &:not(.rdrDayPassive) {
      .rdrDayActive:not(.rdrDayHovered) {
        .rdrDayStartPreview, .rdrDayEndPreview {
          ~ .rdrDayNumber {
            background-color: var(--rdr-blue-l-10);

            span {
              color: var(--rdr-blue);
            }
          }
        }
      }

      .rdrDayStartPreview {
        ~ .rdrDayNumber {
          background-color: var(--rdr-blue-l-10);

          span {
            color: var(--rdr-blue);
          }
        }

        &.rdrDayEndPreview {
          ~ .rdrDayNumber {
            background-color: var(--rdr-blue-l-10);
          }
        }
      }

      &:hover {
        .rdrDayNumber {
          background-color: var(--rdr-blue-l-35);
        }

        .rdrDayStartPreview {
          ~ .rdrDayNumber {
            background-color: var(--rdr-blue-l-10);

            span {
              color: var(--rdr-blue);
            }
          }
        }
      }

      .rdrDayEndPreview ~ .rdrDayNumber {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: var(--rdr-blue-l-10);

        span {
          color: var(--rdr-blue);
        }
      }

      .rdrDayStartPreview ~ .rdrDayNumber {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      .rdrEndEdge ~ .rdrDayNumber {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: var(--rdr-blue);

        span {
          color: var(--rdr-white);
        }
      }

      &.rdrDayToday:not(.rdrDayDisabled) {
        .rdrDayNumber {
          span {
            border: 1px solid var(--rdr-blue);
          }

          &:hover {
            span {
              border: 1px solid var(--rdr-dark-blue);
            }
          }
        }

        &.rdrDayActive:not(.rdrDayHovered) {
          .rdrDayNumber {
            span {
              border: 1px solid var(--rdr-blue);
            }
          }
        }
      }

      & > .rdrDayInPreview ~ .rdrDayNumber {
        background-color: var(--rdr-blue-l-35);

        span {
          color: var(--rdr-blue);
        }
      }

      &.rdrDayToday {
        .rdrStartEdge, .rdrEndEdge {
          & ~ .rdrDayNumber > span {
            border: 1px solid var(--rdr-dark-blue) !important;
          }
        }
      }

      .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
        background-color: var(--rdr-white) !important;

        & ~ .rdrDayNumber {
          border: none !important;
          background-color: var(--rdr-blue-l-10);

          span {
            color: var(--grey-700);
          }
        }

        &.rdrStartEdge {
          & ~ .rdrDayNumber {
            background-color: var(--rdr-blue-end);
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;

            span {
              color: white !important;
            }
          }
        }

        &.rdrEndEdge {
          & ~ .rdrDayNumber {
            background-color: var(--rdr-blue-end);
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;

            span {
              color: white !important;
            }
          }
        }
      }

      &.rdrDayHovered {
        .rdrEndEdge:not(.rdrStartEdge) ~ .rdrDayNumber {
          background-color: var(--rdr-blue-l-10);

          span {
            color: var(--rdr-black) !important;
          }
        }
      }

      &:hover {
        .rdrInRange {
          & ~ .rdrDayNumber {
            span {
              color: var(--rdr-white);
            }
          }
        }
      }

      &:hover {
        .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
          & ~ .rdrDayNumber {
            background-color: var(--rdr-blue-d5);
          }
        }
      }

      &:active, &.focus {
        .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
          & ~ .rdrDayNumber {
            background-color: var(--rdr-blue-end);
          }
        }
      }

      & ~ &:not(:nth-child(7n + 1)) {
        .rdrSelected, .rdrInRange, .rdrEndEdge:not(.rdrStartEdge) {
          & ~ .rdrDayNumber:after {
            content: '';
            height: 100%;
            width: 8px;
            position: absolute;
            right: 100%;
            top: 0;
            background-color: var(--rdr-blue-l-35);
          }
        }
      }
    }

    &.rdrDayPassive {
      pointer-events: none;

      .rdrDayNumber span {
        color: transparent;
      }
    }
  }

  .rdrWeekDay {
    margin: 2px 0 0px 0 !important;
    text-transform: uppercase;
    color: var(--rdr-black-l-40);
    opacity: 0.4;
    box-sizing: inherit;
    text-align: center;
    font-weight: 400;
    line-height: 2.667em;
  }

  .rdrDay, .rdrWeekDay {
    width: 2rem;
    height: 1.5rem;

    padding: 0;

    font-size: 14px;
    line-height: 1.5rem;
    font-weight: normal;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--rdr-white);
    border: none;

  }


  .rdrMonthsVertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .rdrMonthsHorizontal > div > div > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }


  .rdrWeekDays, .rdrDays {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .rdrWeekDays {
    padding: 0 0.833em;
  }

  .rdrDayDisabled {
    cursor: not-allowed;
  }


  .rdrDayToday:not(.rdrDayPassive) {
    .rdrInRange ~ .rdrDayNumber span:after,
    .rdrStartEdge ~ .rdrDayNumber span:after,
    rdrEndEdge ~ .rdrDayNumber span:after,
    .rdrSelected ~ .rdrDayNumber span:after {
      background: var(--rdr-white);
    }
  }

  .rdrDay:not(.rdrDayPassive) {
    .rdrInRange ~ .rdrDayNumber span,
    .rdrStartEdge ~ .rdrDayNumber span,
    .rdrEndEdge ~ .rdrDayNumber span,
    .rdrSelected ~ .rdrDayNumber span {
      //color: rgba(255, 255, 255, 0.85);
    }
  }

  .rdrDayPassive {
    .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
      display: none;
    }
  }

  .rdrDayDisabled:not(.rdrDayPassive) {
    background-color: var(--rdr-disabled-background) !important;
    color: var(--rdr-disabled) !important;

    .rdrDayNumber {
      background-color: var(--rdr-disabled-background) !important;
      color: var(--rdr-disabled) !important;

      span {
        background-color: var(--rdr-disabled-background) !important;
        color: var(--rdr-disabled) !important;
      }
    }

    cursor: initial;
  }
}
