.Wrapper {
  position: fixed;
  bottom: 0;
  max-width: 410px;
  width: 100%;

  &__border {
    max-width: 370px;
    height: 1px;
    border-top: 1px solid var(--gray-200);
    margin: auto;
  }
}

.OnlineGroupList {
  padding: 20px 0px;
  background-color: #fff;

  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
  }

  &__indicator {
    display: inline-block;
    background-color: var(--green-500);
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-right: 8px;
  }

  &__indicator_offline {
    background-color: var(--gray-200);
  }

  &__arrow {
    transform: rotate(180deg);
  }

  &__content {
    height: 155px;
    overflow-y: auto;
    margin-top: 15px;
  }
}

.User {
  min-height: 61px;
  display: flex;
  margin-bottom: 16px;
  margin-left: 40px;

  &__avatar {
    display: inline-block;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    margin-right: 4px;
    margin-top: 2px;
  }

  &__name {
    font-size: 14px;
    color: var(--brand-info);
    line-height: 21px;
    margin-bottom: 4px;
  }

  &__role {
    color: var(--grey);
    font-size: 12px;
    line-height: 18px;
  }
}

.GroupContainer {
  max-width: 320px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  &__expand {
    color: var(--indigo);
    font-size: 12px;
    cursor: default;
  }

  &__operator {
    font-size: 12px;
    line-height: 16px;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 2px 4px;
    border-radius: 2px;
  }
}
