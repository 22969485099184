header.navbar {
  position: relative;
  flex-direction: row;
  min-height: $navbar-height;
  padding: 0;
  background-color: $navbar-bg;
  @include borders($navbar-border);

  .navbar-brand {
    display: inline-block;
    height: $navbar-height;
    padding: $navbar-padding-y $navbar-padding-x;
    margin-right: 0;
    background-color: $navbar-brand-bg;
    background-image: $navbar-brand-logo;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $navbar-brand-logo-size;
    @include borders($navbar-brand-border);
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .nav-item {
    position: relative;
    min-width: 40px;
    margin: 0 !important;
    text-align: center;

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
  }
}

.navbar-brand {
  color: $navbar-active-color;

  @include hover-focus {
    color: $navbar-active-color;
  }
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-btn {
  font-size: 16px;
  color: $gray-light-alt;
  &.btn {
    box-shadow: none;
  }
}

.user-menu-btn {
  font-size: 14px;
  color: var(--primary) !important;
  letter-spacing: 0.02em;
}

.header-menu {
  min-width: 220px;
  .settings-item {
    display: flex;
    align-items: center;
    span {
      line-height: 20px;
    }
    svg {
      font-size: 16px;
      width: 16px;
    }
  }
  &-button {
    color: $blue;
    height: $navbar-height;
    min-width: 170px;
  }
  &-popover {
    padding: 0;
    .justui_popover-body {
      padding: 0.5rem 0;
    }
    .popover {
      left: 6px !important;
      top: -2px !important;
      max-width: unset;
    }
  }
  &-popover-user {
    .popover {
      left: -8px !important;
      top: 0 !important;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    &.dropdown-item {
      padding-right: 1rem;
      padding-left: 1rem;
      svg {
        top: 0;
        margin-left: 8px !important;
      }
    }
    svg.justui_icon:last-child {
      margin-left: 0;
      margin-right: 0.75rem;
    }
    &--heading.dropdown-item {
      display: flex;
      color: var(--gray-500);
      margin-bottom: -8px;
      padding-right: 1rem;
      padding-left: 1rem;
      > div {
        margin-left: 0.25rem;
      }
      b {
        display: block;
        color: black;
        margin-bottom: 0;
      }
      svg {
        top: 2px;
      }
    }
    &:active {
      svg.justui_icon {
        top: 0;
        color: white;
      }
    }
  }

  svg.justui_icon {
    &.right {
      top: 0;
      &.btn-icon {
        margin-right: 0;
      }
    }
    &.left {
      top: 0;
      &.btn-icon {
        margin-left: 0;
        margin-right: 8px;
      }
    }
    &.gray {
      color: var(--gray-800);
    }
  }

  a {
    text-decoration: none;
  }

  .external-link {
    font-size: 12px;
  }
}
