.HideRightPanelButton {
  &:focus {
    background-color: transparent !important;
    border-color: var(--gray-300) !important;
  }
}

.HideRightPanelTooltip {
  .tooltip {
    margin: 0 4px;

    .tooltip-inner {
      background-color: var(--gray-600);
    }
    .arrow::before {
      border-right-color: var(--gray-600) !important;
      border-left-color: var(--gray-600) !important;
    }
  }
}
