// Import Main styles for this application
@import './scss/style.scss';
@import './scss/pseudoTaiwind.scss';

body {
  background-color: var(--gray-alt--gray-50);
  color: var(--gray-800);
}
label {
  color: var(--gray-800);
}

.just-select-selected-item {
  background-color: var(--white);
}

* {
  outline: 0px solid transparent !important;
  &:focus,
  &:hover {
    outline: 0px solid transparent !important;
    &:before,
    &:after {
      outline: 0px solid transparent !important;
    }
  }
  .btn:focus, .btn.focus {
    box-shadow: none !important;
  }
}

#root {
  display: grid;
  grid-template-columns: 3.5rem calc(100% - 3.5rem) !important;
  grid-template-rows: calc(3.5rem) calc(100% - 3.5rem) !important;
  grid-template: 'header header' 'sidebar main';
  height: 100vh;
  width: 100vw;
  &.notAdminRole {
    grid-template: 'header header' 'main main';
  }
  @media (max-width: 620px) {
    grid-template: 'header' 'main';
    grid-template-columns: 100vw !important;
    grid-template-rows: calc(3.5rem) calc(100% - 3.5rem) !important;
    .Sidebar {
      display: none;
    }
  }
}

.Header {
  grid-area: header;
}

.Sidebar {
  grid-area: sidebar;
}

main {
  grid-area: main;
  overflow: hidden;
}

h2,
h1,
h3,
h4,
h5 {
  color: var(--gray-950);
}

.system-status-icon-success {
  background-color: var(--success);
}

.table {
  color: var(--gray-800);
}

//TODO delete when inputs in channels are removed
.custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--primary);
}

//TODO delete when inputs in channels are removed
.switch-success > .switch-input:checked:not(:disabled) ~ .switch-label {
  background-color: var(--primary) !important;
}

//TODO delete when inputs in channels are removed
.switch-success > .switch-input:checked ~ .switch-handle {
  border-color: var(--primary) !important;
}

.form-control {
  height: calc(2.25rem + 2px);
}

.just-ui-custom-scrollbar {
  &.with-gutter {
    scrollbar-gutter: stable;
  }
  &::-webkit-scrollbar {
    position: relative;
    width: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 10px;
    background-clip: padding-box;
    border-right: 5px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
    border-radius: 6px;
    background-color: var(--gray-200);
    cursor: pointer;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
  }

  &_black {
    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-600);
    }
  }
  &_indigo-light {
    &::-webkit-scrollbar-thumb {
      background-color: var(--indigo-300);
    }
  }
  &_pink {
    &::-webkit-scrollbar-thumb {
      background-color: var(--indigo-200);
    }
  }
}

.btn-custom-primary {
  color: var(--primary-600);
  border: 1px solid var(--primary-600);
}

.btn-custom-primary:hover {
  color: var(--white);
  background-color: var(--primary-700);
}

.btn-custom-primary:active {
  color: var(--white);
  background-color: var(--primary-800);
}

.react-select__option--is-selected,
.header-select__option--is-selected {
  background-color: var(--primary) !important;
  color: var(--white);
}

.react-select__option--is-focused,
.header-select__option--is-focused {
  background-color: var(--primary-100) !important;
  color: var(--gray-950) !important;
}
select {
  option:hover {
    background-color: var(--primary) !important;
    color: var(--white);
  }
}

.switch-success .switch-input:hover ~ .switch-label {
  background-color: var(--primary-400);
}

.error {
  color: var(--danger);
}

.no-underline {
  text-decoration: none;
}
