.rdrInputDateContainer {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 34px;
  position: relative;
  width: 200px;

  --rdr-disabled-background: transparent;

  &.rdrDark {
    --rdr-blue: #1e7899;
    --rdr-blue-l-35: #83cce7;
    --rdr-blue-d5: #269ac4;
    --rdr-blue-end: #1e7899;
    --rdr-black: #fff;
    --rdr-white: #1f282d;
    --rdr-disabled: var(--gray);
    --rdr-black-l-93: #4d4d4d;
    --rdr-black-l-40: #cfd8dc;
    --rdr-disabled-background: #273238;
  }

  &.rdrInputDateContainertime,
  &.rdrInputDateContainerdate {
    width: 130px;
  }
  & > button {
    height: 100%;
    width: 100%;
    outline: none;
    position: absolute;
    grid-column: 2/2;
    background: none;
    border: none;

    opacity: 0;

    transition: 300ms ease-in-out;
    &:focus {
      outline: none;
    }
  }

  &.disabled {
    color: var(--gray-300);
    cursor: not-allowed;
  }
}

.rdrInputDate {
  grid-column: 1/3;

  height: 38px;
  width: 100%;
  padding: 9px 12px;

  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 3px;
  background-color: var(--rdr-white);
  color: var(--rdr-black);
  box-shadow: 0 0 0 var(--rdr-blue-l-35);
  transition: 300ms;
  outline: none;
  cursor: pointer;

  &:focus {
    border: 1px solid var(--rdr-blue);
  }

  &::-webkit-input-placeholder {
    color: var(--rdr-black-l-40);
  }
  & * {
    fill: var(--rdr-black-l-40);
  }

  &[data-clean='false'] ~ button {
    opacity: 1;
  }

  &:disabled {
    color: var(--gray-300);
    cursor: not-allowed;
  }
}
