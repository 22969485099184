.AnalyticActionPanel_actionPanel {
  padding: 12px 24px;
  min-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  color: var(--gray-950);

  & > * {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    *:last-child {
      justify-content: flex-end;
    }

    .ReactSelectPrefix__control {
      margin-left: 9px;
      flex-shrink: 0;
      flex-wrap: nowrap;

      & > *:first-child {
        width: auto;
        min-width: 2rem;
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
          margin: 0;
        }
      }

      & > *:last-child {
        width: 1.5rem;
      }
    }
  }

  .AnalyticActionPanel_rightSide {
    gap: 1rem;
  }
  .AnalyticActionPanel_leftSide {
    gap: 1rem;
  }
  .AnalyticActionPanel_downloadReporter {
    padding: 6px 0;
  }

  .AnalyticActionPanel_pageSizeControl {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    .ReactSelectPrefix__control {
      margin: 0!important;
    }
  }

  .AnalyticActionPanel_paginator {
    margin-bottom: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > * {
      display: flex;
    }

    .AnalyticActionPanel_page, .AnalyticActionPanel_previous, .AnalyticActionPanel_next {
      height: 24px;
      min-width: 23px;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 23px;

      &:active {
        outline: none;
      }

      &.disabled {
        path {
          fill: #ccc;
        }
      }


      &_link {
        width: 100%;
        height: 100%;
        padding: 0 8px;
        text-align: center;
        display: block;
        line-height: 23px;
      }

      &.AnalyticActionPanel_active {
        background-color: var(--gray-100);
        border-radius: 1px;
      }
    }

    .AnalyticActionPanel_setPage_input {
      max-width: 3rem;
    }
  }

  .AnalyticActionPanel_setPage_input_container {
    display: grid;
    grid-template-rows: auto;
    align-items: center;
    gap: 8px;

    input {
      padding: 4px;
      width: auto;
      min-width: 3rem;
      max-width: 6rem;
      text-align: center;
      height: auto !important;
    }
  }
}
.AnalyticActionPanel_openSettingsButton {
  background-color: var(--gray-200) !important;
  color: var(--gray) !important;
  & path {
    color: var(--gray) !important;
  }
}
