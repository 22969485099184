@import 'settingsMixins';
.Settings {
  .Group_description {
    max-width: 540px;
    display: block;

  }

  .Groups_createButton {
    max-width: 800px;
    margin-top: 24px;
    @media (max-width: 620px) {
      & > button {
        width: 100%;
      }
    }
  }

  .Groups_group {
    @include element();
    & > *:not(:last-child) {
      width: 40%;
      @media (max-width: 620px) {
        width: calc(100% - 2rem);
      }
    }
    & > *:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.25rem;
      & > * {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & > *:last-child > button {
      border: 1px solid var(--gray-300);
    }
    &_groupText {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_colorMark {
        min-height: 0.5rem;
        min-width: 0.5rem;
        border-radius: 0.5rem;
    }
  }
}
