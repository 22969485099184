.Link {
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  &:not(.short) {
    width: 100%;
    padding: 12px 14px;
    gap: 6px;
    justify-content: flex-start;
  }
  &.short {
    justify-content: center;
    width: 38px;
  }
  &,
  * {
    fill: var(--grey-950);
    text-decoration: none;
    color: var(--gray-950);
  }
  transition: 200ms ease-in-out;
  &.active {
    background-color: var(--indigo-200);
  }
}
