.sidebar {
  padding: $sidebar-padding;
  color: $sidebar-color;
  background: $sidebar-bg;
  @include borders($sidebar-borders);
  overflow: hidden;

  .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 $spacer;
    font-size: 24px;
    font-weight: 800;
    line-height: $navbar-height;
    color: $sidebar-color;
    background: 0;
    border: 0;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .sidebar-nav {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      position: absolute;
      width: 10px;
      margin-left: -10px;
      -webkit-appearance: none;
    }

    // &::-webkit-scrollbar-button {    }

    &::-webkit-scrollbar-track {
      background-color: lighten($sidebar-bg, 5%);
      border-right: 1px solid darken($sidebar-bg, 5%);
      border-left: 1px solid darken($sidebar-bg, 5%);
    }

    // &::-webkit-scrollbar-track-piece {    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: darken($sidebar-bg, 10%);
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px;
    }

    // &::-webkit-scrollbar-corner {    }

    // &::-webkit-resizer {    }
  }

  .nav {
    flex-direction: column !important;

    .nav-title {
      padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
      font-size: 11px;
      font-weight: 600;
      color: $sidebar-nav-title-color;
      text-transform: uppercase;
    }

    .divider {
      height: 10px;
    }

    .nav-item {
      position: relative;
      margin: 0;
      transition: background 0.3s ease-in-out;

      ul {
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        transition: max-height 0.3s ease-in-out;

        li {
          padding: 0;
          list-style: none;
        }
      }

      .nav-link {
        display: block;
        padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
        color: $sidebar-nav-link-color;
        text-decoration: none;
        background: $sidebar-nav-link-bg;
        @include borders($sidebar-nav-link-borders);
        @if $enable-sidebar-nav-rounded {
          border-radius: $border-radius;
        }

        &:hover {
          color: $sidebar-nav-link-hover-color !important;
          background: $sidebar-nav-link-hover-bg !important;
          @include borders($sidebar-nav-link-hover-borders);

          i {
            color: $sidebar-nav-link-hover-icon-color;
          }
        }

        &.active {
          color: $sidebar-nav-link-active-color;
          background: $sidebar-nav-link-active-bg;
          @include borders($sidebar-nav-link-active-borders);

          i {
            color: $sidebar-nav-link-active-icon-color;
          }
        }

        [class^='icon-'],
        [class*=' icon-'] {
          display: inline-block;
          margin-top: -4px;
          vertical-align: middle;
        }

        i {
          width: 20px;
          margin: 0 ($sidebar-nav-link-padding-x * 0.5) 0 0;
          font-size: 14px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
        }

        .badge {
          float: right;
          margin-top: 2px;
          // margin-left: 10px;
        }

        &.nav-dropdown-toggle {
          &::before {
            position: absolute;
            top: ($sidebar-nav-link-padding-y + ($font-size-base * 0.25));
            right: $sidebar-nav-link-padding-x;
            display: block;
            width: ($font-size-base);
            height: ($font-size-base);
            padding: 0;
            font-size: $font-size-base;
            line-height: ($font-size-base * 3 * 0.25);
            text-align: center;
            content: '\2039';
            transition: 0.3s;
          }
        }
      }

      &.nav-dropdown {
        &.open {
          background: $sidebar-nav-dropdown-bg;
          @include borders($sidebar-nav-dropdown-borders);
          @if $enable-sidebar-nav-rounded {
            border-radius: $border-radius;
          }
          > ul,
          > ol {
            // display: inline;
            max-height: 1000px;
          }

          a.nav-link {
            color: $sidebar-nav-dropdown-color;
            border-left: 0 !important;
          }

          > .nav-link.nav-dropdown-toggle::before {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
          }

          .nav-dropdown.open {
            border-left: 0;
          }
        }
        &.nt {
          transition: 0s !important;
          > ul,
          > ol {
            transition: 0s !important;
          }
          a.nav-link {
            &.nav-dropdown-toggle {
              &::before {
                transition: 0s !important;
              }
            }
          }
        }
      }

      a.nav-label {
        display: block;
        padding: ($sidebar-nav-link-padding-y * 0.125) $sidebar-nav-link-padding-x;
        color: $sidebar-nav-title-color;

        &:hover {
          color: $sidebar-color;
          text-decoration: none;
        }

        i {
          width: 20px;
          margin: -3px ($sidebar-nav-link-padding-x * 0.5) 0 0;
          font-size: 10px;
          color: $sidebar-nav-link-icon-color;
          text-align: center;
          vertical-align: middle;
        }
      }

      @if (lightness($sidebar-bg) < 40) {
        .progress {
          background-color: lighten($sidebar-bg, 15%) !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .hidden-cn {
      display: none;
    }
    .sidebar {
      z-index: $zindex-sticky - 1;
      // width: $sidebar-compact-width;

      &:hover {
        overflow: visible;
      }

      .sidebar-nav {
        overflow: visible;
      }

      .nav {
        .nav-title,
        .divider {
          display: none;
        }

        .nav-item {
          width: $sidebar-compact-width;
          overflow: hidden;
          border-left: 0 !important;

          ul {
            background: $sidebar-bg;
          }

          .nav-link {
            position: relative;
            padding-left: 0;
            margin: 0;

            // line-height: $sidebar-compact-height;
            white-space: nowrap;
            border-left: 0 !important;

            &.nav-dropdown-toggle {
              &::before {
                display: none;
              }
            }

            i {
              display: block;
              float: left;
              width: $sidebar-compact-height;
              // height: $sidebar-compact-height;
              padding: 0;
              margin: 0 !important;
              font-size: 18px;
              // line-height: $sidebar-compact-height;
            }

            .badge {
              position: absolute;
              // top: 14px;
              right: 15px;
              display: none;
            }

            &:hover {
              .badge {
                display: inline;
              }
            }
          }

          ul {
            position: absolute;
            left: $sidebar-compact-width;

            li {
              position: relative;
              padding: 0;

              ul,
              ol {
                position: absolute;
                top: 0;
                left: 100%;
              }
            }
          }

          &.nav-dropdown {
            &.open {
              background: $sidebar-nav-link-active-bg;
              > a.nav-link i {
                color: $sidebar-nav-link-active-icon-color;
              }
              > ul,
              > ol {
                display: none;
              }
            }
          }

          &:hover {
            overflow: visible;
            background: $sidebar-nav-link-hover-bg;
            transition: 0s;

            > ul,
            > ol {
              display: inline;
              max-height: 10000px;
              transition: 0s;
            }

            &.nav-dropdown {
              &.open {
                > ul,
                > ol {
                  display: inline;
                }
              }
            }
          }
        }
      }
    }
  }
}
