// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

/*@function custom_darken($color, $amount: 0){
  $hue: hue($color);
  $saturation: floor(saturation($color));
  $lightness: ceil(lightness($color));

  @return hsl($hue, $saturation, $lightness - $amount)
}*/

@mixin button-variant(
  $background,
  $border,
  $hover-background: darken($background, 10%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 20%),
  $active-border: darken($border, 20%),
  $disabled-background: $grey-300,
  $disabled-border: $grey-300,
  $flat-active-bg: $primary-200,
  $focus-shadow: $primary-500,
  $focus-background: $hover-background
) {
  color: color-yiq($background);
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover() {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: color-yiq($background);
    @include gradient-bg($focus-background);
    border-color: white;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width $focus-shadow;
    }
  }

  &.btn-secondary:hover:not(:disabled) {
    color: $grey-950;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $grey-500;
    background-color: $disabled-background;
    border-color: $disabled-border;
    cursor: default;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;
  }

  &.btn-flat {
    border-color: transparent;
    background: transparent;
    color: $background;

    &:not(:disabled) {
      &.btn-secondary {
        color: $grey-950;
      }
      @include hover() {
        color: $hover-background;
        background: rgba(black, 0.04);
        &.btn-secondary {
          color: $grey-950;
        }
      }
    }

    &:focus,
    &.focus {
      background-color: rgba(black, 0.04);
    }

    &.disabled,
    &:disabled {
      color: $disabled-background;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $active-background;
      background: $flat-active-bg;
      border-color: $flat-active-bg;

      &.btn-secondary {
        color: $grey-950;
      }
    }
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: darken($color, 10%),
  $active-color: darken($color, 20%),
  $disabled-color: $grey-300,
  $focus-shadow: $color-hover
) {
  color: $color;
  border-color: $color;

  &.btn-outline-secondary {
    color: $grey-950;
    border-color: $grey-300;
  }
  @include hover() {
    color: white;
    background-color: $color-hover;
    border-color: $color-hover;
    &.btn-outline-secondary:not(:disabled) {
      border-color: $grey-400;
      color: $grey-950;
    }
  }

  &:focus,
  &.focus {
    color: white;
    border-color: white;
    background-color: $color-hover;
    box-shadow: 0 0 0 $btn-focus-width $focus-shadow;

    &.btn-outline-secondary {
      color: $grey-950;
      box-shadow: 0 0 0 $btn-focus-width $focus-shadow, inset 0 0 0 1px $grey-400;
    }
  }

  &.disabled,
  &:disabled {
    color: $disabled-color;
    border-color: $disabled-color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $white;
    background-color: $active-color;
    border-color: $active-color;

    &.btn-outline-secondary {
      border-color: $grey-400;
      color: $grey-950;
    }

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.3);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width $focus-shadow;
      }
    }
  }
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: transparent;
    border-color: $active-color;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.3);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.3);
      }
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  line-height: #{$line-height}rem;
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);

  // custom styles
  .iconLeft {
    margin-right: $padding-y;
  }

  .iconRight {
    margin-left: $padding-y;
  }

  &.btn-square {
    padding: $padding-x;

    &.justui_dropdown-toggle {
      line-height: 0;
    }
  }
}
