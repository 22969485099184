.justui_button {
  display: flex;
  align-items: center;
  justify-content: center;

  &.noIconsMargin{
    .iconLeft, .iconRight {
      margin: 0;
    }
  }
}

.justui_icon-button {
  &.compact {
    padding: 0.375rem;
    &:disabled {
      color: #666666;
    }
    svg {
      width: 1rem;
      height: 1rem;
      &.dropdown-caret {
        width: 0.5rem;
        height: 0.5rem;
        font-size: 0.8rem;
      }
    }
    .dropdown-caret {
      margin-left: 4px;
    }
  }
}
