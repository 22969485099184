.Chat_container {
  height: 100%;
  position: relative;

  background-color: var(--blue-blue-100);

  display: flex;
  flex-direction: column;

  &__with_banner {
    height: calc(100% - 45px) !important;
  }
}
